<template>
  <div class='appeal container-global-color container-global-table'>
    <top-button :button_list="radios" @ok="handleChangeRadio" :active="radio" />
    <el-date-picker
      class="date-picker"
      v-model="selectTime"
      @change="handleChange"
      value-format="yyyy-MM-dd"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>

    <!-- <el-table
      :data="tableData"
      style="width: 100%">

      <el-table-column
        v-for="tabelHeader in tableHeaders"
        :key="tabelHeader.id"
        :prop="tabelHeader.id"
        :label="tabelHeader.name"
        align="center"
      >
      <template slot-scope="scope">
        <el-popover
          v-if="tabelHeader.name === '内容'"
          placement="right"
          width="400"
          trigger="click">
          <p> {{ scope.row.receivreason }} </p>
          <span slot="reference"
            :class="['text-ellipsis']"
          >{{ scope.row.receivinfo.length > 10
           ? scope.row.receivinfo.slice(0,9) + '...'
           : scope.row.receivinfo
           }}</span>
        </el-popover>
        <span v-else-if="tabelHeader.name === '类型'"> {{ commentType[scope.row.auditingType] }}</span>
        <span v-else> {{ scope.row[[tabelHeader.id]] }}</span>
      </template>
      </el-table-column>

      <el-table-column
        prop="action"
        label="操作"
        align="center"
        width="320px"
      >
        <template slot-scope="scope">
          <span>
            <span class="actions" @click = "handleActions('AG', scope.row)">同意退款</span>
            <span class="middle">|</span>
            <span class="actions" @click = "handleActions('NM', scope.row)">拒绝退款</span>
          </span>
        </template>
      </el-table-column>

    </el-table> -->

    <component
        :is="'appealTable'"
        :fileKey="fileKey"
        :tableHeaders="tableHeaders"
        :tableData="tableData"
        @action="handleComponentsActions"
        @updateAddressList="getAddress"
        @updateList="getList"
    />

    <el-pagination
      class="pagination"
      :page-size="params.size"
      layout="prev, pager, next"
      :current-page.sync="params.page"
      @current-change="handleCurrentChange"
      :total="total">
    </el-pagination>


    <el-dialog
      :title="elDialogData.dialogTitle"
      :visible.sync="elDialogData.dialogVisible"
      width="30%"
      @close="elDialogData.dialogVisible = false"
    >
      <div v-if="isValue">
        <el-select
            style="margin: 15px 0"
            v-model="elDialogData.address"
            placeholder="请选择"
            v-if="elDialogData.key == 'agreeReturn' || elDialogData.key == 'agreeExchangeGoods'"
            no-data-text="请设置收货地址">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px"><i class="el-icon-close" @click="handleDeleAddress(item)"/></span>
          </el-option>
        </el-select>
        <el-input
            v-model="elDialogData.value"
            :placeholder="`请输入${elDialogData.dialogTitle}的内容`"
            v-else
            ></el-input>
<!--        v-if="elDialogData.key == 'agreeExchangeGoods'"-->
      </div>
      <p v-else>确定要进行{{elDialogData.dialogTitle}}操作吗？</p>
      <template slot="footer">
        <el-button @click="elDialogData.dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit()">提交</el-button>
      </template>
    </el-dialog>

  </div>
</template>
<script>
import {appealTable, TopButton} from '../components'

export default {
  components: {
    TopButton,
    appealTable,
  },
  data() {
    const commentType = {
      '1': '评论',
      '2': '申诉',
    }
    return {
      commentType,
      elDialogData: {
        key: '',
        dialogVisible: false,
        dialogTitle: '',
        value: '',
        address: ''
      },

      radios: [
        { label: 1, name: '待退款' },
        { label: 2, name: '已退款' },
        { label: 3, name: '拒绝退款' },
        { label: 4, name: '待退货退款' },
        { label: 5, name: '已退货退款' },
        { label: 6, name: '拒绝退货退款' },
        { label: 7, name: '待换货' },
        { label: 8, name: '已换货' },
        { label: 9, name: '拒绝换货'}
      ],

      radio: 1,

      /**
       * 状态有：
       *  待退款
       *  已退款
       *  拒绝退款
       *  待退货
       *  已退货
       *  拒绝退货
       *  待换货
       *  已换货
       *  拒绝换货
       */
      tableHeaders: [
        { id: 'order_num', name: '订单编号' },
        { id: 'receivprice', name: '订单金额' },
        { id: 'creat_time', name: '时间' },
        { id: 'receivinfo', name: '内容' },
        { id: 'type', name: '状态' },
        // { id: 'auditingCount', name: '用户' },
      ],
      tableData: [],

      selectTime: ['',''],
      params: {
        page: 1,
        size: 10,
        startTime: '',
        endTime: '',
        afterSaletype: ''
      },
      total: 1,

      componentsName: 'appealTable',
      fileKey: 'appeal',
      options: []
    };
  },

  computed: {
    isValue() {
      return [ 'agreeExchangeGoods', 'refuseRefund', 'refuseReturn', 'refuseExchangeGoods', 'agreeReturn', 'changeGoods'].some(item => item == this.elDialogData.key)
    }
  },

  watch: {},

  methods: {

    // 打开弹窗
    // handleOrderDialog(type) {
    //   this.elDialogData.key = type
    //   const titleType = {
    //     'order': '修改物流单号',
    //   }
    //   this.elDialogData.dialogTitle = titleType[type]
    //   this.elDialogData.dialogVisible = true
    // },

    // 弹窗确认按钮
    async handleSubmit() {
      try{
        /**
         * this.elDialogData.key
         * agreeRefund: 同意退款
         * refuseRefund: 拒绝退款
         * agreeReturn: 同意退货退款
         * refuseReturn: 拒绝退货退款
         * agreeExchangeGoods: 同意换货
         * refuseExchangeGoods: 拒绝换货
         */
        if (this.elDialogData.key == 'agreeReturn' && this.elDialogData.address == '') return  this.$message.error('请选择收货地址');
        this.elDialogData.isValue = this.isValue
        if (this.elDialogData.key == 'changeGoods' && !this.elDialogData.value)return  this.$message.error('请选择内容');
        console.log(this.elDialogData);
        const res = await this.$store.dispatch('afterSale/actions', this.elDialogData)
        if(res.code == 200) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
        this.elDialogData.dialogVisible = false
        this.elDialogData.value = ''
        await this.getList()
      }catch(e){
        //TODO handle the exception
        console.log('操作出现问题了~', e);
      }
    },

    // 获取地址列表
    async getAddress() {
      try{
        const res = await this.$store.dispatch('afterSale/getAddressListApi')
        this.options = res.data ?
        res.data.map(item => {
          return {label: `${item.provincecode}-${item.citycode}-${item.countycode}-${item.address}`, value: item.id}
        }) : []
      }catch(e){
        //TODO handle the exception
        console.log(e, '获取地址列表失败');
      }
    },

    // 获取数据列表
    async getList() {
      try{
        const res = await this.$store.dispatch('afterSale/getAfterSaleListApi', this.params)
        this.tableData = res.data
        this.total = res.total
      }catch(e){
        //TODO handle the exception
        console.log(e, '获取申诉列表失败');
      }
    },

    // 页面操作
    async handleComponentsActions(params) {
      // const { key, dialogTitle, id} = params
      this.elDialogData = {
        ...this.elDialogData,
        ...params
      }
    },

    // 时间筛选
    async handleChange(val) {
      if(!this.selectTime){
        this.selectTime = ['', '']
      }
      this.params.startTime = this.selectTime[0]
      this.params.endTime = this.selectTime[1]
      await this.getList()
    },

    // 选择类型给
    async handleChangeRadio(val) {
      this.radio = val
      const parmasMethod = {
        1: {
            state: 0,
            afterSaletype: 1,
          },
        2: {
          state: 1,
          afterSaletype: 1,
        },
        3: {
          state: 2,
          afterSaletype: 1,
        },
        4: {
          state: 0,
          afterSaletype: 2,
        },
        5: {
          state: 1,
          afterSaletype: 2,
        },
        6: {
          state: 2,
          afterSaletype: 2,
        },
        7: {
          state: 0,
          afterSaletype: 3,
        },
        8: {
          state: 1,
          afterSaletype: 3,
        },
        9: {
          state: 2,
          afterSaletype: 3,
        },
      }
      this.params = {
        ...this.params,
        ...parmasMethod[val]
      }
      await this.getList()
    },

    // 分页筛选
    async handleCurrentChange(val) {
      this.params.page = val
      await this.getList()
    },

  //  删除收货地址
    async handleDeleAddress(val) {
      const res =  await this.$store.dispatch('user/deleteAddressApi', {id: val.value})
      if(res.code == 200) {
        this.elDialogData.address = ''
        this.$message.success(res.msg)
      } else {
        this.$message.warning(res.msg)
      }
      await this.getAddress()
    }
  },

  created() {

  },

  async mounted() {
    await this.getList()
    await this.getAddress()
  },
}
</script>
<style scoped lang="scss">
.appeal {
  .date-picker {
    margin-bottom: 15px;
  }
  .actions {
    cursor: pointer;
    color: red
  }
  .middle {
    margin: 0 10px;
  }
  .text-ellipsis {
    cursor: pointer;
    color: #8690ee;
  }
  .pagination {
    text-align: center;
    padding-top: 20px;
  }
}

::v-deep .el-radio {
  margin-right: 15px;
}
</style>
