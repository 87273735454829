import router from "@/router";
import store from "@/store";
import { Message } from 'element-ui'
import { Decrypt } from '@/utils/cryptoAES'



router.beforeEach(async (to,from,next)=>{
     // 前台免登录跳转
    if (to.query.token) {
        const token = Decrypt(to.query.token)
        const tongfanstate = Decrypt(to.query.tongfanstate)
        localStorage.setItem('token', token)
        localStorage.setItem('tongfanstate', tongfanstate)
        if (to.path === '/home') {
            next('/home/salesPro')
        }
    }
    let token = localStorage.getItem('token')
    let tongfanstate = localStorage.getItem('tongfanstate')

    /**
     * tongfanstate: {
     *  0: '没有开通橱窗',
     *  1: '正在审核资料',
     *  2: '已经审核过资料具有所有的权限'
     * }
     * tongfanstate === 0 || 1 没有开通橱窗但是可以进入橱窗后台只有设子可操作
     * tongfanstate === 2 正在审核资料  tongfanstate===2 && 已经上传10张作品 可开启稿位
     * tongfanstate === 2 除了稿位都是可操作
     */

    if (to.path !=='/login'){
        if (!token || !tongfanstate){
            Message({
                message: "请先登陆",
                type: 'error'
            })
            return  next("/login")
        }
        // if (tongfanstate*1 === 0){
        //     Message({
        //         message: "未开通橱窗",
        //         type: 'error'
        //     })
        //     return  next("/login")
        // }
        // if (tongfanstate*1 === 1){
        //     Message({
        //         message: "审核中",
        //         type: 'error'
        //     })
        //     return  next("/login")
        // }
        if (to.path === '/home/saleMobileCapture'){
            return false
        }
        next()
    }else {
        next()
    }
});
