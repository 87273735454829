<template>
	<div class="order-list container-global-color container-global-table">
		<div class="filter-tip">
			<top-button :button_list="radios" @ok="handleSelect" :active="radio" />
			<div>
				<span>筛选日期：</span>
				<el-date-picker
					v-model="selectTime"
					@change="handleChange"
					value-format="yyyy-MM-dd"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
			</div>
			<download-excel
				:data="tableData"
				:fields="json_fields"
				type="xls"
				worksheet="My Worksheet"
				name="用户信息">
				<el-button class="download">导出表格</el-button>
			</download-excel>
		</div>
		<el-table
			:data="tableData"
			:stripe="true"
			border
			:header-cell-style="{ background: '#fafafa' }"
			style="width: 100%">
			<el-table-column
				v-for="tabelHeader in tableHeaders"
				:key="tabelHeader.id"
				:prop="tabelHeader.id"
				:label="tabelHeader.name"
				align="center">
				<template slot-scope="scope">
					<span
						v-if="tabelHeader.name === '查看详情'"
						class="actions title"
						@click="
							$router.push({
								path: 'detail',
								query: {
									good_uuid: scope.row.good_uuid,
									type: scope.row.good_type,
								},
							})
						"
						>{{ scope.row.good_title }}</span
					>
					<span v-else>{{ scope.row[tabelHeader.id] }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="action" label="操作" align="center">
				<template slot-scope="scope">
					<span @click="handleOrderDialog('order', scope)" class="table-actions"
						>填写物流单号</span
					>
					<span class="middle">|</span>
					<span
						@click="handleOrderDialog('goodInfo', scope)"
						class="table-actions"
						>收货信息</span
					>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination
			class="pagination"
			:page-size="params.size"
			layout="prev, pager, next"
			:current-page.sync="params.page"
			@current-change="handleCurrentChange"
			:total="total" />

		<el-dialog
			:title="elDialogData.dialogTitle"
			:visible.sync="elDialogData.dialogVisible"
			width="30%"
			@close="elDialogData.dialogVisible = false">
			<el-form
				ref="formOrder"
				:model="elDialogData.form"
				label-width="100px"
				v-if="elDialogData.key === 'order'">
				<el-form-item label="填写物流单号">
					<el-input v-model="elDialogData.form.orderNumber"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button @click="elDialogData.dialogVisible = false"
						>取消</el-button
					>
					<el-button
						type="primary"
						@click="handleSubmit('order')"
						:disabled="isOrderSumit"
						>提交</el-button
					>
				</el-form-item>
			</el-form>

			<el-form ref="form" :model="elDialogData.form" v-else>
				<el-form-item>
					<i class="el-icon-copy-document action" @click="handleCopy"></i>
				</el-form-item>
				<el-form-item label="姓 名 ：">
					<span>{{ elDialogData.form.userName }}</span>
				</el-form-item>
				<el-form-item label="联系方式：">
					<span>{{ elDialogData.form.phone }}</span>
				</el-form-item>
				<el-form-item label="地 址 ：">
					<span>{{ elDialogData.form.address }}</span>
				</el-form-item>
				<el-form-item style="text-align: center">
					<el-button type="primary" @click="elDialogData.dialogVisible = false"
						>确定</el-button
					>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import { TopButton } from '@/views/Home/components'
export default {
  components: {TopButton},
  data() {
    return {
      radios: [
        { label: 1, name: '全部' },
        { label: 2, name: '虚拟' },
        { label: 3, name: '实体' },
      ],
      radio: 1,

      tableHeaders: [
        { id: 'parent_order_num', name: '订单编号' },
        { id: 'count', name: '数量' },
        { id: 'recharge_price', name: '订单金额' },
        { id: 'creat_time', name: '成交时间' },
        { id: 'good_title', name: '查看详情' },
        { id: 'good_type_key', name: '成交类型' },
        { id: 'user', name: '用户' },
      ],
      tableData: [],

      json_fields: {},

      selectTime: '',

      // 弹窗数据
      elDialogData: {
        key: '',
        dialogVisible: false,
        dialogTitle: '',
        form: {
          orderNumber: '',
          id: '',
          userName: '',
          phone: '',
          address: '',
        },
      },

      total: 0,

      params: {
        startTime: '',
        endTime: '',
        good_type: '',
        page: 1,
        size: 10,
      },

      isOrderSumit: false
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 筛选
    async handleSelect(val) {
      this.radio = val
      const radioTytpe = {
        1: '',
        2: 1,
        3: 2
      }
      this.params.good_type = radioTytpe[val]
      await this.getList()
    },

    // 获取列表数据
    async getList () {
      try{
        const res = await this.$store.dispatch('goods/getGoodsList', this.params)
        this.tableData = res.data
        this.total = res.total
      }catch(e){
        //TODO handle the exception
        console.log(e, '获取订单列表出错');
      }
    },

    // 筛选时间
    async handleChange() {
      if (!!this.selectTime) {
        this.params.startTime = this.selectTime[0]
        this.params.endTime = this.selectTime[1]
      } else {
        this.params.startTime = ''
        this.params.endTime = ''
      }
      await this.getList()
    },

    // 打开弹窗
    handleOrderDialog(type, scope) {
      this.elDialogData.key = type
      const titleType = {
        'order': '填写物流单号',
        'goodInfo': '收货信息'
      }
      this.elDialogData.form = scope.row.form
      this.elDialogData.dialogTitle = titleType[type]
      this.elDialogData.dialogVisible = true
    },

    // 结束弹窗
    handleSubmit(type) {
      const functionTypt = {
        'order': 'handleAddOrder',
        'goodInfo': 'handleAddGoodInfo'
      }
      this[functionTypt[type]]()
    },

    // 添加单号校验 && 后续操作 && 关闭弹窗
    async handleAddOrder() {
      try{
        if(!this.elDialogData.form.orderNumber.trim()) {
          this.elDialogData.form.orderNumber = ''
          return this.$message.error('请填写物流单号')
        }
        const data = {
          id: this.elDialogData.form.id || '', // 货物单号
          express_num: this.elDialogData.form.orderNumber // 物流单号
        }

        this.isOrderSumit = true
        const res = await this.$store.dispatch('goods/uploadOrderNo', data)
        if(res.code === 200) {
          // 刷新数据
          await this.getList()
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
        console.log(this.elDialogData, 'this.elDialogData');
        this.elDialogData.form.orderNumber = ''
        this.elDialogData.dialogVisible = false
        this.isOrderSumit = false
      }catch(e){
        //TODO handle the exception
        console.log(e, '添加物流单号出错了~');
      }
    },

    // 查看收货信息
    handleAddGoodInfo() {
      this.elDialogData.dialogVisible = false
    },

    // 复制收货信息
    handleCopy() {
      const copyValue = [
        {
          key: '姓名',
          value: this.elDialogData.form.userName
        }, {
          key: '联系方式',
          value: this.elDialogData.form.phone
        }, {
          key: '收货地址',
          value: this.elDialogData.form.address
        }
      ]
      const value = copyValue.map(item => `${item.key}：${item.value}`).join(',')
      const copyInput = document.createElement('input')
      document.body.appendChild(copyInput)
      copyInput.setAttribute('value', value)
      copyInput.select()
      document.execCommand('Copy')
      this.$message.success('复制成功！')
      copyInput.remove()
    },

    async handleCurrentChange(val) {
      this.params.page = val
      await this.getList()
    },


  },
  created() {

  },
  mounted() {
    // 导出列表
    this.tableHeaders.forEach(item => {
      this.json_fields[item.name] = item.id
    })

    this.getList()
  },
}
</script>
<style scoped lang="scss">
.order-list {
	.filter-tip {
		display: flex;
		justify-content: space-between;
		.selectTime {
			width: 50%;
		}
		.download {
			background-color: #6357ff;
			color: #fff;
			border: none;
		}
	}
	.middle {
		margin: 0 10px;
	}

	::v-deep .pagination {
		text-align: right;
		padding-top: 20px;
		.el-pager li.active {
			color: #6357ff;
			cursor: default;
		}
	}
	.actions {
		cursor: pointer;
		color: red;
	}
	.action {
		cursor: pointer;
	}
}
</style>
