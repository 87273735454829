import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import JsonExcel from 'vue-json-excel';
import store from './store'

Vue.config.productionTip = false
Vue.use(ElementUI);

import "@/assets/tc.css";
import "@/assets/reset.css";
import "@/plugins/beforRouter"
import "@/plugins/directive"
import "@/assets/fontFamily/font_f.css";
import "@/assets/font_lcdw0m0pwyp/iconfont.css";

Vue.component('downloadExcel', JsonExcel);

Vue.prototype.isLoading = (isLoad) => {
  store.commit('changeLoading', isLoad)
}



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
