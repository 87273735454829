<template>
<div class='applying banner'>
  <div class="thank">
      <img src="@/assets/img/10.png" alt="">
      <div class="tip">
        <span>已收到您的申请，请耐心等待审核通过~</span>
      </div>
      <el-button round @click="handkeGoChu">返回首页</el-button>

    </div>
</div>
</template>

<script>

export default {
components: {},
data() {
return {

};
},
computed: {},
watch: {},
methods: {
  handkeGoChu() {
    window.open('https://chuoooo.com/', '_blank')
  }
},
created() {

},
mounted() {
  const isApply = JSON.parse(localStorage.getItem('isApplyMsg'))
  if(isApply == 2 ) this.$router.push('/')
},
}
</script>
<style scoped lang="scss">
.applying {
  .thank {
    margin: 0 auto;
    text-align: center;
    img {
      width: 20%;
    }
    .tip {
      margin: 0px 0 20px 0;
    }
  }
}

::v-deep .el-button {
  background: linear-gradient(to right, #ffaed5 , #f184f0 , #b375f7)!important;
  color: #fff;
  border: none;
  font-size: 24px;
  height: 50px;
  padding: 10px 50px;
  margin-bottom: 10px;
  border-radius: 25px;
}
</style>