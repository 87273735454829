<template>
<div class='no-found banner'>
  <div  class="sorry">
    <img src="@/assets/img/sorry.jpg" alt="">
  </div>
</div>
</template>

<script>

export default {
components: {},
data() {
return {

};
},
computed: {},
watch: {},
methods: {

},
created() {

},
mounted() {

},
}
</script>
<style scoped lang="scss">
.no-found {
  .sorry {
    width: 50%;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
}
</style>