var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auditing container-global-color container-global-table"},[_c('top-button',{attrs:{"button_list":_vm.radios,"active":_vm.radio},on:{"ok":_vm.handleSelect}}),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"stripe":true,"border":"","header-cell-style":{ background: '#fafafa' }}},[_vm._l((_vm.tableHeaders),function(tabelHeader){return _c('el-table-column',{key:tabelHeader.id,attrs:{"prop":tabelHeader.id,"label":tabelHeader.name,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(tabelHeader.name === '审核状态')?_c('span',[_vm._v(" "+_vm._s(_vm.auditStatusType[scope.row.state]))]):(tabelHeader.name === '查看详情')?_c('span',{staticClass:"table-actions title",on:{"click":function($event){return _vm.$router.push({
        path: 'detail',
        query: {good_uuid: scope.row.good_uuid, type: scope.row.good_type}
      })}}},[_vm._v(_vm._s(scope.row.good_title))]):_c('span',[_vm._v(" "+_vm._s(scope.row[[tabelHeader.id]]))])]}}],null,true)})}),_c('el-table-column',{attrs:{"prop":"action","label":"操作","align":"center","width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!scope.row.state)?_c('span',[_c('el-popconfirm',{attrs:{"popper-class":"my-popconfirm","title":"确定要将此商品撤回审核吗？"},on:{"confirm":function($event){return _vm.handleDelete(scope.row)}}},[_c('span',{staticClass:"table-actions",attrs:{"slot":"reference"},slot:"reference"},[_vm._v("撤回审核")])])],1):_c('span',[_c('span',{staticClass:"table-actions",on:{"click":function($event){return _vm.$router.push({
              path: '/',
              query: { good_uuid: scope.row.good_uuid, type: scope.row.good_type }
            })}}},[_vm._v("修改信息")])])]}}])})],2),_c('el-pagination',{staticClass:"pagination",attrs:{"page-size":_vm.pageSize.keySize,"layout":"prev, pager, next, jumper","current-page":_vm.currentPage,"total":_vm.total},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"current-change":_vm.handleCurrentChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }