<template>
	<div class="radios-group">
		<span
			v-for="item in button_list"
			:key="item.label"
			@click="
				() => {
					$emit('ok', item.label);
				}
			"
			:class="{ activeType: active === item.label }"
			>{{ item.name }}</span
		>
	</div>
</template>

<script>
export default {
	props: {
		button_list: {
			type: Array,
			default: () => [],
		},

		active: {
			type: Number,
			default: 1,
		},
	},
};
</script>

<style lang="scss" scoped>
.radios-group {
	display: flex;
	gap: 40px;
	align-items: center;
  margin-bottom: 30px;
  border-bottom: 1px solid #f5f5f5;
  font-size: 18px;
	> span {
		// padding: 10px;
		// border: 1px solid #DCDFE6;
		// border-radius: 10px;
     padding-bottom: 30px;
		cursor: pointer;
		// background-color: #fff;
	}
	.activeType {
		// background-color: #6357FF;
		color: #6357ff;
   
    border-bottom: 2px solid #6357ff;
	}
}
</style>
