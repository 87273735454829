<template>
<div class='enterprise-apply banner'>
  <div class="apply-title">
    <h1>企业账户申请</h1>
    <span>本页面所收集的身份信息仅用作企业账户申请，请如实填写</span>
  </div>

  <el-form ref="form" :model="form" label-width="150px" class="form" :rules="rules">
    <!-- <el-form-item label="法人姓名" prop="name">
      <el-input
        v-model="form.name"
        placeholder="请输入法人姓名"
        @focus="handleFocu('name')"
        :class="[active === 'name' ? 'active' : '', 'subInput']"
      ></el-input>
    </el-form-item>

    <el-form-item label="法人身份证号" prop="region">
      <el-input
        v-model="form.region"
        placeholder="请输入身份证号"
        @focus="handleFocu('region')"
        :class="[active === 'region' ? 'active' : '', 'subInput']"
      ></el-input>
    </el-form-item> -->
    <el-form-item label="企业名称" prop="name">
      <el-input
        v-model="form.name"
        placeholder="请输入企业名称"
        @focus="handleFocu('name')"
        :class="[active === 'name' ? 'active' : '', 'subInput']"
      ></el-input>
    </el-form-item>

    <el-form-item label="运营人姓名" prop="manager">
      <el-input
        v-model="form.manager"
        placeholder="请输入运营人姓名"
        @focus="handleFocu('manager')"
        :class="[active === 'manager' ? 'active' : '', 'subInput']"
      ></el-input>
    </el-form-item>

    <el-form-item label="联系电话" prop="iphone">
      <el-input
        v-model="form.iphone"
        placeholder="请输入联系电话"
        @focus="handleFocu('iphone')"
        :class="[active === 'iphone' ? 'active' : '', 'subInput']"
      ></el-input>
    </el-form-item>

    <el-form-item label="企业邮箱" prop="email">
      <el-input
        v-model="form.email"
        placeholder="请输入企业邮箱"
        @focus="handleFocu('email')"
        :class="[active === 'email' ? 'active' : '', 'subInput']"
      ></el-input>
    </el-form-item>

    <el-form-item label="运营人身份证" prop="enterprise_idcard">
      <el-input
        v-model="form.enterprise_idcard"
        placeholder="请输入运营人身份证号"
        @focus="handleFocu('enterprise_idcard')"
        :class="[active === 'enterprise_idcard' ? 'active' : '', 'subInput']"
      ></el-input>
    </el-form-item>

    <div class="test-upload subInput">
      <el-form-item label="运营人证件上传" prop="face">
        <el-upload
          action="/api/base/upload"
          list-type="picture-card"
          :before-upload="(flie => {beforeAvatarUpload('face', flie)})"
          :on-preview="handlePictureCardPreview"
          :on-success="(file) => {handleAvatarSuccess('face', file)}"
          :class="[!!form.face ? 'hide' : 'show']"
          :on-remove="(file) => {handleRemove('face', file)}">
          <i class="el-icon-circle-plus-outline upload-content" >
            <span>上传运营人身份证正面</span>
          </i>
        </el-upload>
      </el-form-item>
      <el-form-item prop="back" class="back">
        <el-upload
          action="/api/base/upload"
          list-type="picture-card"
          :before-upload="(flie => {beforeAvatarUpload('back', flie)})"
          :on-preview="handlePictureCardPreview"
          :on-success="(file) => {handleAvatarSuccess('back', file)}"
          :class="[!!form.back ? 'hide' : 'show']"
          :on-remove="(file) => {handleRemove('back', file)}">
          <i class="el-icon-circle-plus-outline upload-content">
            <span>上传运营人身份证反面</span>
          </i>
        </el-upload>
      </el-form-item>
    </div>

    <div class="tips">
      <div>提示： 请上传边框完整、图像清晰的图片，个人资料部分清晰可见，请勿作任何修改。</div>
      <div>支持jpg/png格式，单个文件大小不得超过5M</div>
    </div>

    <el-form-item prop="license" class="license" label="营业执照上传">
      <el-upload
        action="/api/base/upload"
        list-type="picture-card"
        :before-upload="(flie => {beforeAvatarUpload('license', flie)})"
        :on-preview="handlePictureCardPreview"
        :on-success="(file) => {handleAvatarSuccess('license', file)}"
        :class="[!!form.license ? 'hide' : 'show']"
        :on-remove="(file) => {handleRemove('license', file)}">
        <i class="el-icon-circle-plus-outline upload-content">
          <span>上传企业营业执照</span>
        </i>
      </el-upload>
    </el-form-item>

    <div class="tips">
      <div>提示： 请上传边框完整、图像清晰的图片，个人资料部分清晰可见，请勿作任何修改。</div>
      <div>支持jpg/png格式，单个文件大小不得超过5M</div>
    </div>

    <el-form-item prop="otherLicense" class="otherLicense" label="上传申请书">
      <a style="color: #2e82ff;cursor: pointer;" :href="baseUrl+'certificationletter.doc'">下载申请书</a>
      <el-upload
        action="/api/base/upload"
        list-type="picture-card"
        :before-upload="(flie => {beforeAvatarUpload('otherLicense', flie)})"
        :on-preview="handlePictureCardPreview"
        :class="[!!form.otherLicense ? 'hide' : 'show']"
        :on-success="(file) => {handleAvatarSuccess('otherLicense', file)}"
        :on-remove="(file) => {handleRemove('otherLicense', file)}">
        <!-- :class="[!!form.otherLicense ? 'hide' : 'show']" -->

        <i class="el-icon-circle-plus-outline upload-content">
          <span>下载模板，打印并填写上传照片</span>
        </i>
      </el-upload>
    </el-form-item>

    <div class="tips">
      <div>提示：请上传边框完整、图片清晰的图片，支持jpg/png格式，单个文件大小不得超过5M</div>
    </div>

    <el-form-item prop="acount" label="企业/机构银行账号">
      <el-input
        v-model="form.acount"
        placeholder="仅支持银行卡，请填写银行卡号"
        @focus="handleFocu('acount')"
        @input="regCardNumber"
        :class="[active === 'acount' ? 'active' : '', 'subInput']"
      ></el-input>
    </el-form-item>

    <el-form-item label="企业/机构开户支行" prop="cardIssuer">
      <el-input
        v-model="form.cardIssuer"
        placeholder="请填写企业/机构开户支行"
        @focus="handleFocu('cardIssuer')"
        :class="[active === 'cardIssuer' ? 'active' : '', 'subInput']"
      ></el-input>
    </el-form-item>

    <el-form-item label="统一社会信用代码" prop="socialCode">
      <el-input
        v-model="form.socialCode"
        placeholder="请输入统一社会信用代码"
        @focus="handleFocu('socialCode')"
        :class="[active === 'socialCode' ? 'active' : '', 'subInput']"
      ></el-input>
    </el-form-item>

    <el-form-item prop="checked">
      <el-checkbox v-model="form.checked" style="margin-left: 10px;"/>
      点击申请即表示同意<a href="#/Resign" style="color:#6357ff">《触圈橱窗注册协议》</a>协议且愿意遵守该协议中规定事项
    </el-form-item>

    <el-form-item  class="submit-btn subInput">
      <el-button round @click="submitForm()">提交申请</el-button>
    </el-form-item>
  </el-form>
  <el-dialog
    :visible.sync="dialogVisiblePay"
    title="提示"
    width="50%"
    center
  >
    <p style="text-align: center">企业认证需要支付300押金</p>
    <p style="color: red;text-align: center">tip: 押金会在注销企业账号时退还，周期为7个工作日内。</p>
    <template slot="footer" style="text-align: center">
      <button @click="dialogVisiblePay = false" class="default-btn">取消</button>
      <button @click="submitPayAndForm" class="default-btn submit-btn-dialog">确定</button>
    </template>
  </el-dialog>
</div>
</template>

<script>
import { enterpriseFormRules } from '@/utils/validate'
export default {
  components: {},
  data() {
    return {
      rules: enterpriseFormRules,
      form: {
        name: '',   // 企业名称
        // region: '',
        manager: '', // 运营人
        iphone: '', // 手机号
        email: '', // 邮箱
        face: '', // 身份证正面
        back: '', // 身份证反面
        license: '', // 营业执照
        otherLicense: '', // 申请书
        acount: '', // 银行账号
        cardIssuer: '', // 开户行
        socialCode: '', // 社会信用代码
        enterprise_idcard: '' // 身份证号
      },
      active: '',
      baseUrl: this.$store.state.baseUrl, // 图片上传地址
      dialogVisiblePay: false, // 是否显示支付弹窗
    };
  },

  computed: {},

  watch: {},

  methods: {
    beforeAvatarUpload(type, file) {
      this.isLoading(true)
      // 判断类型
      const fileType = file.type === 'image/jpeg' || 'image/png'
      if (!fileType) return this.$message.error('上传图片只能是 JPG/PNG 格式!')
      // 小于5M
      const isLt5M = file.size / 1024 / 1024 < 5
      if(!isLt5M) return this.$message.error('上传图片大小不能超过 5MB!')
    },

    handleRemove(type, file) {
      this.form[type] = ''
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleAvatarSuccess(key, file) {
      // if (key === 'otherLicense') {
      //   this.form[key].push(file.msg)
      // } else {
      //   this.form[key] = file.msg;
      // }
      this.form[key] = file.msg;
      this.isLoading(false)
    },

    handleFocu(key) {
      this.active = key
    },

    // 整理格式
    regCardNumber () {
      this.form.acount = this.form.acount.replace(/\s/g, '').replace(/[^\d]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ');
    },

    submitForm() {
      if (!this.form.checked) return this.$message.error('请先同意/用户协议!')
      this.$refs.form.validate(async valid => {
        console.log(valid);
        if(valid) {
          this.dialogVisiblePay = true
        }
      })
    },

    // 缴费
    async submitPayAndForm () {
      const res = await this.$store.dispatch('user/getCompanyPayApi', {price: 300})
      if (res?.code === 200) {
        const data = {
          enterprise_name: this.form.manager || '',
          idcard_front: this.form.face || '',
          idcard_contrary: this.form.back || '',
          enter_lic_img: this.form.license || '',
          other_img: this.form.otherLicense || '',
          enter_name: this.form.name || '',
          enter_lic: this.form.socialCode || '',
          bank_deposit: this.form.cardIssuer || '',
          bank_card: this.form.acount.replace(/\s/g, ''),
          enterprise_idcard: this.form.enterprise_idcard || '',
          tell: this.form.iphone || '',
          email: this.form.email || '',
        }
        console.log(data, '里面是什么内容');
        const res = await this.$store.dispatch('user/getCompanyApplyApi', data)
        console.log(res);
        if(res?.code == 200) {
          this.$message.success(res?.msg)
          location.reload()
        } else {
          this.$message.warning(res?.msg)
        }
      } else {
        this.$message.warning(res?.msg)
      }
      this.dialogVisiblePay = false
    }
  },

  created() {
  },

  mounted() {

  },
}
</script>
<style scoped lang="scss">
.enterprise-apply {
  .apply-title {
    text-align: center;
    margin-bottom: 20px;
    h1 {
      margin-top: 0;
    }
    span {
      color: #d7d7db;
    }
  }

  .form {
    .subInput {
      width: 80%;
    }

    .test-upload {
      display: flex;
      flex-wrap: wrap;
    }

    .tips {
      margin: 10px 0 20px 150px;
      color: #cacaca;
      // text-shadow: 0 10px 10px #d8d8d8;
    }

    .submit-btn {
      text-align: center;
    }
  }

  .default-btn {
    background-color: #fff;
    padding: 5px 15px;
    font-size: 18px;
    border: 1px solid #d7d7db;
    border-radius: 15px;
    width: 100px;
    margin-right: 10px;
    cursor: pointer;
  }
  .submit-btn-dialog {
    background: linear-gradient(to right, #fecee5 , #f7b5f6 , #d1acfa)!important;
    border: none;
    color: #fff;
  }
}

::v-deep .test-upload {
  .el-form-item__error {
    top: 100%;
    left: 0%;
  }
}

::v-deep .el-upload--picture-card{
  width: 280px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  .upload-content {
    display: flex;
    flex-direction: column;
    color: #ccc;
    font-size: 30px;

    span {
      font-size: 16px;
      margin-top: 5px;
    }
  }
}

::v-deep .el-upload-list__item  {
  width: 280px;
  height: 200px;
  border: none;
}

// ::v-deep .el-upload-list--picture-card .el-upload-list__item-thumbnail {
//   object-fit: cover;
// }

::v-deep .hide {
  .el-upload--picture-card {
    display: none;
  }
}

::v-deep .active {
  .el-input__inner {
    border: 1px solid #6357ff;
    border-color: #6357ff !important;
  }
}

::v-deep .el-button {
  background: linear-gradient(to right, #fecee5 , #f7b5f6 , #d1acfa)!important;
  color: #fff;
  border: none;
  font-size: 18px;
  padding: 10px 50px;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner  {
  background-color: #6357ff;
  border-color: #6357ff;
}
</style>
