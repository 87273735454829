<template>
	<div class="income-center container-global-color container-global-table">
		<div class="income-title">
			<div class="all-count">
				<div class="amount">￥{{ income.allCount.toFixed(2) }}</div>
				<div>累积收益</div>
			</div>
			<div class="current-count">
				<div class="amount">￥{{ income.currentCount.toFixed(2) }}</div>
				<div>当月收益</div>
			</div>
			<div class="counted">
				<div class="amount">￥{{ income.counted.toFixed(2) }}</div>
				<div>已提现收益</div>
			</div>
		</div>

		<div class="open-enterprise">
			<el-button
				type="primary"
				class="enterprise download"
				size="mini"
				@click="handleGoApply"
				>开通企业账户</el-button
			>
		</div>

		<div class="can-payouts">
			<div class="money">
				<div>
					<div class="amount">￥{{ income.canPauouts.toFixed(2) }}</div>
					<div>可提现收益</div>
				</div>
				<div>
					<div class="amount">
						￥{{ income.ToBeCreditedRevenue.toFixed(2) }}
					</div>
					<div>待入账</div>
				</div>
			</div>
			<div class="agreement-submit">
				<div>
					点击出售即表示同意
					<a
						href="#/agreeWithdrawal"
						target="_blank"
						style="color: #6357ff; text-decoration: none">
						《提现服务协议》
					</a>
					<el-radio :label="1" v-model="isAgreen"
						>且愿意遵守该协议中规定事项</el-radio
					>
				</div>
				<div class="submit">
					<el-button size="mini" @click="handlePayoutsSet" class="download"
						>提现设置</el-button
					>

					<el-button size="mini" @click="handlePayouts" class="download"
						>提现</el-button
					>
				</div>
			</div>
		</div>

		<div class="table-all">
			<div>收益明细</div>

			<div class="table-filter">
				<top-button
					:button_list="radios"
					@ok="handleFilterList"
					:active="radio" />
				<el-date-picker
					class="date-picker"
					v-model="selectTime"
					@change="handleChange"
					value-format="yyyy-MM-dd"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
			</div>

			<div>
				<el-table 
				:data="tableData" 
				style="width: 100%" 
				:stripe="true" border
				:header-cell-style="{ background: '#fafafa' }"
				>
					<el-table-column
						v-for="tabelHeader in tableHeaders"
						:key="tabelHeader.id"
						:prop="tabelHeader.id"
						:label="tabelHeader.name"
						align="center">
						<template slot-scope="scope">
							<span
								v-if="tabelHeader.name === '查看详情'"
								class="table-actions title"
								@click="
									$router.push({
										path: 'detail',
										query: {
											good_uuid: scope.row.good_uuid,
											type: scope.row.good_type,
										},
									})
								">
								{{ scope.row.good_title }}
							</span>
							<span v-else-if="tabelHeader.name === '成交类型'">
								{{ scope.row.good_type === 1 ? "虚拟" : "实体" }}
							</span>
							<span v-else>{{ scope.row[tabelHeader.id] }}</span>
						</template>
					</el-table-column>
				</el-table>

				<el-pagination
					class="pagination"
					:page-size="params.size"
					layout="prev, pager, next"
					:current-page.sync="params.page"
					@current-change="handleCurrentChange"
					:total="total" />
			</div>
		</div>

		<!-- 添加账户/修改账户 -->
		<el-dialog :title="setTitle" :visible.sync="dialogVisible" width="30%">
			<el-form ref="form" :model="AliForm" :rules="rules">
				<el-form-item label="支付宝账号" prop="account">
					<el-input v-model="AliForm.account"></el-input>
				</el-form-item>
				<el-form-item
					label="认证名称（支付宝个人真实姓名或企业认证名称）"
					prop="name">
					<el-input v-model="AliForm.name"></el-input>
				</el-form-item>
				<el-form-item style="color: red">
					Tips: 1年度只能更改一次提现账户
				</el-form-item>
				<el-form-item>
					<div class="btns">
						<el-button @click="dialogVisible = false">取消</el-button>
						<el-button type="primary" @click="handleSubmit">确定</el-button>
					</div>
				</el-form-item>
			</el-form>
		</el-dialog>

		<!-- 核对信息 -->
		<el-dialog title="核对信息" :visible.sync="checkDialogVisible" width="40%">
			<el-form :model="AliForm">
				<el-form-item label="支付宝账号:">
					{{ AliForm.account }}
				</el-form-item>
				<el-form-item label="认证姓名:">
					{{ AliForm.name }}
				</el-form-item>
				<el-form-item label="联系客服:"> _chuoooo_com </el-form-item>
				<el-form-item style="color: red">
					Tips: 1年度只能更改一次提现账户。<span v-if="AliForm.update_time"
						>上次修改时间为{{ AliForm.update_time }}</span
					>
				</el-form-item>
				<el-form-item>
					<div class="btns">
						<el-button @click="checkDialogVisible = false">取消</el-button>
						<el-button type="primary" @click="handleSubmitCheck"
							>确定</el-button
						>
					</div>
				</el-form-item>
			</el-form>
		</el-dialog>

		<!-- 纳税说明 -->
		<el-dialog
			title="纳税说明"
			:visible.sync="taxDialogVisible"
			width="40%"
			class="dialog">
			<p>
				根据个人所得税法相关规定，画师从平台取得的美术作品相关交易收入，触圈有义务代缴个人所得税，相关纳税信息可在<a
					href="#/agreeWithdrawal"
					target="__blank"
					>《提现服务协议》</a
				>中查询。
			</p>
			<div class="btns">
				<el-button @click="taxDialogVisible = false">取消</el-button>
				<el-button type="primary" @click="handlePrimary">确定</el-button>
			</div>
		</el-dialog>

		<!-- 纳税方式 -->
		<el-dialog
			title="纳税信息"
			:visible.sync="taxMethodDialogVisible"
			width="30%"
			class="dialog">
			<div style="color: red; margin-bottom: 30px">
				tip:免税额度内任意提现，当月提现累计达到收税额度之后，当月将无法再提现，请合理安排提现时间
			</div>

			<span>
				参照《个人所得税法》，800元/月以下不扣个税;达到800元至4000元之间的，收入先扣减800元后再交20%的个税等。详情请点开<a
					href="#/agreeWithdrawal"
					target="__blank"
					>《提现服务协议》</a
				>里的内容查看
			</span>
			<div class="btns">
				<el-button @click="taxMethodDialogVisible = false">取消</el-button>
				<el-button type="primary" @click="handleTaxMethod">确定</el-button>
			</div>
		</el-dialog>

		<!-- 提现弹窗 -->
		<el-dialog title="申请提现" :visible.sync="payoutDialogVisible" width="50%">
			<el-form ref="formPayout" :model="formPayout" :rules="AliWithdrawForm">
				<el-form-item label="提现金额" prop="price">
					<div class="price">
						<el-input
							v-model="formPayout.price"
							placeholder="请输入提现金额"
							class="input" />
						<div class="tip">
							操作成功之后，金额将会在提现日之后的7个工作日到账
						</div>
					</div>
				</el-form-item>

				<el-form-item label="支付密码" prop="pwd">
					<el-input
						v-model="formPayout.pwd"
						placeholder="请输入支付密码"
						style="width: 50%" />
				</el-form-item>

				<el-form-item label="提现账户" prop="account">
					<el-select v-model="formPayout.account" placeholder="请选择提现账户">
						<el-option
							v-for="item in options"
							:key="item.id"
							:label="item.account"
							:value="item.account">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item>
					点击“立即提现”标识知晓且同意
					<a href="#/agreeWithdrawal" target="__blank"> 《提现服务协议》 </a>
				</el-form-item>
			</el-form>
			<div class="btns">
				<el-button @click="payoutDialogVisible = false">取消</el-button>
				<el-button type="primary" @click="handlePayout">立即提现</el-button>
			</div>
		</el-dialog>

		<!-- 企业认证 -->
		<el-dialog title="企业认证" :visible.sync="ApplyDialog" width="30%">
			<p>请前往主站登录-->个人中心-->身份认证，进行企业认证</p>
			<span slot="footer" class="dialog-footer">
				<el-button @click="ApplyDialog = false">取 消</el-button>
				<el-button type="primary" @click="handleApply">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { AliForm, AliWithdrawForm } from "@/utils/validate";
import { TopButton } from "@/views/Home/components";

export default {
	components: { TopButton },

	data() {
		return {
			rules: AliForm,
			AliWithdrawForm: AliWithdrawForm,
			radios: [
				{ label: 1, name: "全部" },
				{ label: 2, name: "虚拟" },
				{ label: 3, name: "实体" },
			],
			radio: 1,
			selectTime: [],

			tableHeaders: [
				{ id: "good_uuid", name: "订单编号" },
				{ id: "recharge_price", name: "订单金额" },
				{ id: "creat_time", name: "成交时间" },
				{ id: "lookForDetail", name: "查看详情" },
				{ id: "good_type_key", name: "成交类型" },
			],
			tableData: [],

			isAgreen: 0,

			income: {
				allCount: 0,
				currentCount: 0,
				counted: 0,
				canPauouts: 0,
				ToBeCreditedRevenue: 0,
			},

			dialogVisible: false,
			token: JSON.parse(localStorage.getItem("token")),
			AliForm: {
				account: "",
				name: "",
			},

			checkDialogVisible: false,

			// 纳税
			taxDialogVisible: false,

			// 纳税方式
			taxMethodDialogVisible: false,

			//  提现弹窗
			payoutDialogVisible: false,
			// 企业认证
			ApplyDialog: false,

			options: [],
			formPayout: {
				price: "",
				pwd: "",
				account: "",
			},

			params: {
				startTime: "",
				endTime: "",
				type: "",
				page: 1,
				size: 10,
			},
			total: 0,
		};
	},

	computed: {
		setTitle() {
			return this.AliForm.id ? "修改账户" : "添加账户";
		},
	},

	watch: {},

	methods: {
		// 筛选
		async handleFilterList(val) {
			this.radio = val;
			const radioTytpe = {
				1: "",
				2: 1,
				3: 2,
			};
			this.params.type = radioTytpe[val];
			await this.getInfo();
		},

		// 筛选时间
		async handleChange(val) {
			if (!!this.selectTime) {
				this.params.startTime = this.selectTime[0];
				this.params.endTime = this.selectTime[1];
			} else {
				this.params.startTime = "";
				this.params.endTime = "";
			}
			await this.getInfo();
		},

		// 提现
		handlePayouts() {
			if (!this.isAgreen)
				return this.$message.error("请先同意《用户服务协议》");
			this.taxDialogVisible = true;
		},

		handleGoApply() {
			this.$router.push("/enterpriseApply");
		},

		// 前往认证
		handleApply() {
			window.open("https://chuoooo.com/UserInfo", "_blank");
			this.ApplyDialog = false;
		},

		// 获取收益信息
		async getInfo() {
			try {
				const res = await this.$store.dispatch(
					"income/getInComeInfo",
					this.params
				);
				// 支付宝账号信息
				const Ali = await this.$store.dispatch(
					"income/getAlipayInfo",
					this.token
				);
				this.AliForm = Ali.count ? Ali.res[0] : this.AliForm;
				this.options = Ali.res;
				this.income = {
					allCount: res.allRevenue * 1 + res.canRevenue * 1, // 累积
					currentCount: res.lastMonths, // lastMonths当月
					canPauouts: res.canRevenue, // canRevenue 可提现收益
					counted: res.allRevenue, // 已提现
					ToBeCreditedRevenue: res.ToBeCreditedRevenue, // 待入账
				};
				this.total = res.list.total;
				this.tableData = res.list.data;
			} catch (e) {
				console.log(e, "获取收益信息出错了~");
				//TODO handle the exception
			}
		},

		handlePayoutsSet() {
			if (!this.isAgreen)
				return this.$message.error("请先同意《用户服务协议》");
			this.dialogVisible = true;
		},

		handleSubmit() {
			this.$refs.form.validate((valid) => {
				if (valid) {
					this.checkDialogVisible = true;
				}
			});
		},

		async handleSubmitCheck() {
			try {
				const res = await this.$store.dispatch(
					"income/updateAlipayInfo",
					this.AliForm
				);
				if (res.code === 200) {
					this.$message.success(res.msg);
				} else {
					this.$message.error(res.msg);
				}
				this.checkDialogVisible = false;
				this.dialogVisible = false;
				await this.getInfo();
			} catch (e) {
				//TODO handle the exception
				console.log(e, "修改账户出错了~");
			}
		},

		// 纳税方式
		handlePrimary() {
			this.taxDialogVisible = false;
			this.taxMethodDialogVisible = true;
		},

		// 关闭纳税方式弹窗
		handleTaxMethod() {
			this.taxMethodDialogVisible = false;
			// 开启提现弹窗
			this.payoutDialogVisible = true;
		},

		handlePayout() {
			try {
				this.$refs.formPayout.validate(async (valid) => {
					if (valid) {
						if (!!!this.formPayout.price)
							return this.$message.error("请输入提现金额");
						const data = {
							// type: 'income', // 收入提现：income；余额提现：money
							money: this.formPayout.price, // 提现金额
							password: this.formPayout.pwd, // 支付密码
							pay: 1, // 固定值
							interest: this.formPayout.account, // 提现账户
							token: JSON.parse(localStorage.getItem("token")),
							pay_type: 2, //默认第二种
						};
						// 记录提现操作
						await this.$store.dispatch("income/getLogInCome", {
							token: JSON.parse(localStorage.getItem("token")),
							ip: sessionStorage.getItem("ip") || "",
							content: "提现" + JSON.stringify(data),
						});
						// 提现
						const res = await this.$store.dispatch(
							"income/withdrawInCome",
							data
						);
						if (res.code == 200) {
							this.$message.success(res.msg);
						} else {
							this.$message.error(res.msg);
						}
						this.payoutDialogVisible = false;
						this.getInfo();
					}
				});
			} catch (e) {
				//TODO handle the exception
				console.log(e, "提现接口出错了~");
			}
		},

		async handleCurrentChange(val) {
			console.log("分页", val);
			this.params.page = val;
			await this.getInfo();
		},
	},

	created() {},

	async mounted() {
		await this.getInfo();
	},
};
</script>
<style scoped lang="scss">
.income-center {
	.amount {
		font-size: 18px;
	}
	.income-title {
		display: flex;
		height: 200px;
		justify-content: center;
		align-items: center;
		text-align: center;
		.amount {
			color: #fe1d5f;
			font-weight: bold;
		}
		.all-count {
			flex: 1;
		}
		.current-count {
			flex: 1;
		}
		.counted {
			flex: 1;
		}
	}
	.open-enterprise {
		position: relative;
		width: 100%;
		.enterprise {
			position: absolute;
			top: 5px;
			right: 0;
		}
	}
	.can-payouts {
		border-top: 1px solid #ccc;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		height: 100px;
		padding-top: 60px;
		font-size: 14px;
		.amount {
			font-size: 18px;
		}
		.agreement-submit {
			margin-top: 30px;
			text-align: center;
			.submit {
				margin-top: 20px;
				text-align: center;
			}
		}
		.money {
			display: flex;
			justify-content: space-between;
			min-width: 50%;
			text-align: center;
			.amount {
				color: #fe1d5f;
				font-weight: bold;
			}
		}
	}

	.table-all {
		margin-top: 40px;
		.table-filter {
			margin-top: 40px;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	.price {
		display: flex;
		.tip {
			flex: 1;
			padding-left: 5px;
		}
		.input {
			flex: 1;
		}
	}

	.dialog {
		text-align: center;
	}
	.btns {
		display: flex;
		justify-content: flex-end;
		margin-top: 40px;
	}
	.actions {
		cursor: pointer;
		color: red;
	}

  ::v-deep .pagination {
    text-align: right;
    padding-top: 20px;
    .el-pager li.active {
      color: #6357ff;
      cursor: default;
    }
  }
	.date-picker {
		width: 20%;
	}

	.download {
		background-color: #6357ff;
		color: #fff;
		border: none;
	}
}
</style>
