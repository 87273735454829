<template>
  <div class='message banner'>
    <div class="header">系统通知</div>
    <div class="all-message">
      <!-- 不知道要不要添加点击跳转功能 @click="() => {$router.push({path: '/comment'})}" -->
      <div v-for="(item, index) in VHtml" :key="index" class="message-item" >
        <div class="title">{{ item.title }}</div>
        <div v-html="item.text"></div>
      </div>
      <el-pagination
        class="pagination"
        :page-size="params.size"
        layout="prev, pager, next"
        :current-page.sync="params.page"
        @current-change="handleCurrentChange"
        :total="params.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>

export default {

  components: {},

  data() {
    return {
      msgData: [],
      params: {
        page: 1,
        total: 0,
        size: 10
      }
    };
  },

  computed: {
    VHtml () {
      const errUrl = [ 'https://chuoooo.cn/', 'https://www.chuoooo.cn/', 'https://www.chuoooo.com/']
      if (this.msgData.length > 0) {
        let newVHtml = this.msgData.map(item => {
          if (!!item.text && item.text.lastIndexOf('/#')) {
            item.text = item.text.replace('/#', '')
          }
          return item
        })
        let finaMsg = []
        newVHtml.forEach(item => {
          errUrl.forEach(itemE => {
            let itemO = item
            if (!!item.text && item.text.lastIndexOf(itemE) > -1) {
              itemO.text = item.text.replaceAll(itemE, 'https://chuoooo.com/')
            }
            finaMsg.push(itemO)
          })
        })
        finaMsg = Array.from(new Set(finaMsg))
        return finaMsg
      }
    }
  },

  watch: {},

  methods: {
    async getAllMessage() {
      try{
        const res = await this.$store.dispatch('user/getNoticeApi', { token: JSON.parse(localStorage.getItem('token')), ...this.params})
        this.msgData = res.data
        this.params.total = res.total
        // console.log(res);
      }catch(e){
        //TODO handle the exception
        console.log(e, '获取系统通知出错了~~');
      }
    },

    // 分页器
    async handleCurrentChange(val) {
      this.params.page = val
      await this.getAllMessage()
    }
  },

  created() {

  },

  mounted() {
    this.getAllMessage()
  },
}
</script>
<style scoped lang="scss">
.message {
  padding: 30px;
  .header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 50px;
  }
  .all-message {
    
    .message-item {
      // cursor: pointer;
      margin: 20px 0;
      border-bottom: 1px solid #f1f1fa;
      padding-bottom: 10px;
      .title {
        font-size: 20px;
        font-weight: 700;
        margin: 10px 0;
        color: #333333;
      }
      .content {
        font-size: 16px;
        color: #91909d;
        margin: 10px 0;
      }
    }
  ::v-deep .pagination {
    text-align: right;
    padding-top: 20px;
    .el-pager li.active {
      color: #6357ff;
      cursor: default;
    }
  }
  }
}
</style>