<template>
	<div class="selling  container-global-color container-global-table">
		<top-button :button_list="radios" @ok="handleSelect" :active="radio" />

		<div>
			<!-- @sort-change="handleSortChange" 排序 -->
			<el-table 
			:data="tableData" 
			:stripe="true" 
			style="width: 100%" 
			border
			:header-cell-style="{ background: '#fafafa' }"
			>
				<el-table-column
					v-for="header in tableHeader"
					:key="header.id"
					:label="header.name"
					:prop="header.id"
					:sortable="header.isSort"
					align="center">
					<template slot-scope="scope">
						<span
							v-if="header.name === '查看详情'"
							class="actions title"
							@click="
								$router.push({
									path: 'detail',
									query: {
										good_uuid: scope.row.good_uuid,
										type: scope.row.good_type,
									},
								})
							"
							>{{ scope.row.good_title }}</span
						>
						<span v-else>{{ scope.row[header.id] }}</span>
					</template>
				</el-table-column>

				<el-table-column label="操作" width="180px" align="center">
					<template slot-scope="scope">
						<span
							class="actions"
							@click="
								$router.push({
									path: '/',
									query: {
										good_uuid: scope.row.good_uuid,
										type: scope.row.good_type,
									},
								})
							"
							>修改信息</span
						>
						<span class="middle">|</span>
						<el-popconfirm
							popper-class="my-popconfirm" 
							title="确定要下架此商品吗？"
							@confirm="handleDelete(scope.row)">
							<span class="actions" slot="reference">下架商品</span>
						</el-popconfirm>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<el-pagination
			class="pagination"
			:page-size="pageSize.keySize"
			layout="prev, pager, next"
			:current-page.sync="currentPage"
			@current-change="handleCurrentChange"
			:total="total">
		</el-pagination>
	</div>
</template>

<script>
import { TopButton } from "@/views/Home/components";
export default {
	components: {
		TopButton,
	},

	data() {
		return {
			currentPage: 1,
			visible: false,
			radios: [
				{ label: 1, name: "全部" },
				{ label: 2, name: "虚拟" },
				{ label: 3, name: "实体" },
			],
			radio: 1,
			tableHeader: [
				{ id: "good_uuid", name: "出售单号" },
				// { id: 'good_price', name: '出售金额' },
				{ id: "creat_time", name: "上架时间" },
				{ id: "lookForDetail", name: "查看详情" },
				{ id: "good_type_key", name: "出售类型" },
				// { id: 'good_salenum', name: '出售数量' },

				{ id: "good_price", name: "价格", isSort: true },
				{ id: "good_time", name: "时间", isSort: true },
				{ id: "good_salenum", name: "销售", isSort: true },
			],
			tableData: [],
			pageSize: {
				page: 1,
				size: 10,
			},
			total: 0,
			goodType: "",
		};
	},

	computed: {},

	watch: {},

	methods: {
		// 下架
		async handleDelete(raw) {
			try {
				let params = raw;
				params.typeKey = "down";
				const { msg, code } = await this.$store.dispatch(
					"goods/deleteGoods",
					params
				);
				let msgType = "success";
				if (code !== 200) {
					msgType = "error";
				}
				this.$message[msgType](msg);
				await this.getList();
			} catch (e) {
				//TODO handle the exception
				console.log(e, "下架商品出错了~~");
			}
		},

		// 获取数据
		async getList() {
			try {
				const { data, total } = await this.$store.dispatch(
					"goods/getProductList",
					{
						order: "",
						sort: "desc",
						...this.pageSize,
						type: 1,
						good_type: this.goodType,
					}
				);
				this.total = total;
				this.tableData = data;
			} catch (e) {
				console.log(e, "获取商品列表出错了~~");
			}
		},

		// 筛选虚拟、实体
		async handleSelect(val) {
			try {
				this.radio = val;
				this.pageSize.page = 1;
				this.currentPage = 1;
				const good_type = {
					1: "",
					2: "1",
					3: "2",
				};
				this.goodType = good_type[val];
				await this.getList();
			} catch (e) {
				//TODO handle the exception
				console.log(e, "筛选商品列表出错了~~");
			}
		},

		// 分页器
		async handleCurrentChange(val) {
			this.pageSize.page = val;
			this.currentPage = val;
			await this.getList();
		},
	},

	created() {},
	async mounted() {
		await this.getList();
	},
};
</script>
<style scoped lang="scss">
.selling {
	.middle {
		margin: 0 10px;
	}
	.actions {
		cursor: pointer;
		color: red;
	}
  ::v-deep .pagination {
    text-align: right;
    padding-top: 20px;
    .el-pager li.active {
      color: #6357ff;
      cursor: default;
    }
  }
	.title {
		color: blue;
	}
}
</style>
