import {
    getMyApply,
    getNotice,
    getNoticeCount,
    getCompanyApply,
    getCompanyPay,
    createAddress,
    deleteAddress
} from '@/api';

 const user = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    // 个人认证
    async getApply({commit}, data) {
      const {code, msg} = await getMyApply(data);
      return {code, msg};
    },

    // 企业缴费
    async getCompanyPayApi({ commit }, data) {
      const { code, msg } = await getCompanyPay(data);
      return { code, msg };
    },

    // 企业认证
    async getCompanyApplyApi({ commit }, data) {
      const {code, msg} = await getCompanyApply(data);
      return {code, msg};
    },

    // 获取系统通知
    async getNoticeApi({ commit }, params) {
      return  await getNotice(params)
    },

    // 获取系统通知数量
    async getNoticeCountApi({ commit }, params) {
      return  await getNoticeCount(params)
    },

    //  商家收货地址
    async createAddressApi({ commit }, data) {
      return await createAddress(data)
    },

    // 删除收货地址
    async deleteAddressApi({ commit }, params) {
      return await deleteAddress(params)
    },
  },

}



export default user;
