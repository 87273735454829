import {
  createRelease,
  getVitualProductList,
  getPhysicalProductList,
  createReleasePhysical,
  getVitualProductDetail,
  getPhysicalProductDetail,
  updateVitualProduct,
  updatePhysicalProduct,
  deleteVitualProduct,
  deletePhysicalProduct,
  getProductList,
  getProductListForPartyA,
  createOrderInfo,
  getWithdrawAudit,
  endOrderApi
} from '@/api'

const goods = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    // 添加产品
    async addGoods({ commit }, data) {
      // const res = await createRelease(data)
      let res = {}
      if (data.good_type == 1) {
       res = await createRelease(data)
      } else {
        res = await createReleasePhysical(data)
      }
      console.log(res);
      return res
    },

    // 获取列表
    async getAllGoods({ commit }, params) {
      const {keySize, size, page, pTotal, vTotal} = params
      // const vitualList = await getVitualProductList({size, page, who: 'my'})
      // const vitualList = await getVitualProductList(params)
      // keySzie - vitualList.data.length

      // const newKeySize = vitualList.data ? keySize - vitualList.data.length : keySize
      // const physicalList = await getPhysicalProductList({size: newKeySize, page, who: 'my'})
      // // const physicalList = await getPhysicalProductList(params)/
      // console.log(vitualList.data.length);
      // console.log(vitualList);

      // console.log(physicalList.data.length);
      // console.log(physicalList);

      // 上一页 prePage
      // const prePage = page - 1
      // // 分别推算当前请求数据长度
      // const pCurrrentSize = pTotal - prePage * size // 实体数据剩余
      // const vCurrrentSize = vTotal - prePage * size // 虚拟数据剩余
      // // 判断先执行那个接口
      // // 实体剩余大于虚拟剩余
      // // 定义返回数据
      // let vitualList = null
      // let physicalList = null
      // if (pCurrrentSize > vCurrrentSize) {
      //   // 先执行虚拟
      //   vitualList = await getVitualProductList({size, page, who: 'my'})
      //   let newKeySize = null
      //   // 如果vitualList.data存在
      //   console.log('先执行虚拟');
      //   if (!!(vitualList.data)) {
      //     newKeySize = vitualList.data ? keySize - vitualList.data.length : keySize
      //   } else {
      //     // 如果vitualList.data不存在
      //     newKeySize = keySize
      //   }
      //   console.log(newKeySize, '实体');

      //   physicalList = await getPhysicalProductList({size: newKeySize, page, who: 'my'})
      // } else {
      //   // 先执行实体
      //   physicalList = await getPhysicalProductList({size, page, who: 'my'})
      //   // const newKeySize = physicalList.data ? keySize - physicalList.data.length : keySize
      //   let newKeySize = null
      //   // physicalList.data存在
      //   console.log('先执行实体');

      //   if (!!physicalList.data) {
      //     newKeySize = physicalList.data ? keySize - physicalList.data.length : keySize
      //   } else {
      //     // physicalList.data不存在
      //     newKeySize = keySize
      //   }
      //   console.log(newKeySize, '虚拟');
      //   vitualList = await getVitualProductList({size: newKeySize, page, who: 'my'})
      // }


      // 第一次执行
      // let total =0;
      // let allList=[];



      // const vitualList = await getVitualProductList({size:5, page, who: 'my'})
      // total  = vitualList.total;
      // if(vitualList.data){
      //   allList = [...allList,...vitualList.data]
      // }

      // const newKeySize = vitualList.data ?  vitualList.data.length - 5 :-5
      // // const newKeySize = vitualList.data ? keySize - vitualList.data.length : keySize
      // // const physicalList = await getPhysicalProductList({size: newKeySize, page, who: 'my'})
      // let  newPhysicalList = {}
      // if (newKeySize < 0 ) {
      //   newPhysicalList = await getPhysicalProductList({size: -newKeySize, page, who: 'my'})
      //   if(newPhysicalList.data){
      //     allList = [...allList,...newPhysicalList.data]
      //   }

      //   // console.log(newPhysicalList, 'newPhysicalList');
      // }

      // const physicalList = await getPhysicalProductList({size:5, page, who: 'my'})
      // if(physicalList.data){
      //   allList = [...allList,...physicalList.data]
      // }
      // total = physicalList.total+total
      // const newKeySizeTest = physicalList.data ?  physicalList.data.length - 5 :-5
      // let newVitualList = {}
      // if (newKeySizeTest < 0) {
      //   newVitualList = await getVitualProductList({size: -newKeySizeTest, page, who: 'my'})
      //   if(newVitualList.data){
      //     allList = [...allList,...newVitualList.data]
      //   }
      // }

      // const PList = [ ...physicalList.data,  ...newPhysicalList.data]
      // const VList = [ ...vitualList.data, ...newVitualList.data]



      // const vData = vitualList.data ? vitualList.data : [] // 虚拟列表
      // const pData = physicalList.data ? physicalList.data : [] // 实体列表

      // const allList1 = {
      //   // vTotal: vitualList.total,
      //   // pTotal: physicalList.total,
      //   // total: vitualList.total*1 + physicalList.total*1,
      //   // total: VList.total*1 + PList.total*1,
      //   // // 虚拟和实体不在一个接口，进行合并，根据时间进行排序
      //   // data:  [...PList, ...VList].sort((a, b) => b.creat_time < a.creat_time ? 1 : -1).map((item, index) => {
      //   //   item.good_type_key = item.good_type === 1 ? '虚拟' : '实体'
      //   //   item.good_time = item.creat_time
      //   //   item.sell = index
      //   //   return item
      //   // })
      //   total,
      //   // 虚拟和实体不在一个接口，进行合并，根据时间进行排序
      //   data:  [...allList].sort((a, b) => b.creat_time < a.creat_time ? 1 : -1).map((item, index) => {
      //     item.good_type_key = item.good_type === 1 ? '虚拟' : '实体'
      //     item.good_time = item.creat_time
      //     item.sell = index
      //     return item
      //   })
      // }
      // console.log(allList1,'allListallListallListallListallList');
      // return allList1
    },

    // 获取虚拟列表数据
    async getVitualList({commit}, params) {
      const {data, total} = await getVitualProductList(params)
      const dataList = data ? data.map((item, index) => {
        item.good_type_key = item.good_type === 1 ? '虚拟' : '实体'
        item.good_time = item.creat_time
        item.sell = index
        return item
      }) : [];

      return {data: dataList, total}
    },

    // 获取实体列表数据
    async getPhysicalList({commit}, params) {
      const {data, total} = await getPhysicalProductList(params)
      const dataList = data ? data.map((item, index) => {
        item.good_type_key = item.good_type === 1 ? '虚拟' : '实体'
        item.good_time = item.creat_time
        item.sell = index
        return item
      }) : []
      return {data: dataList, total}
    },


    // 获取后台产品列表
    async getProductList({commit}, params) {
      const {data, total} = await getProductList(params)
      const dataList = data ? data.map((item, index) => {
        item.good_type_key = item.good_type === 1 ? '虚拟' : '实体'
        item.good_time = item.creat_time
        return item
      }) : []
      return {data: dataList, total}
    },

    // 撤回审核
    async withdrawAudit({commit}, params) {
      return await getWithdrawAudit(params)
    },

    // 获取虚拟产品详情
    async getVitualDetail({commit}, params) {
      const {data, total} = await getVitualProductDetail(params)
      // console.log(res);
      data.good_pic = data.good_pic.split('|')
      return { data, total }
    },

    // 获取实体产品详情
    async getPhysicalDetail({commit}, params) {
      const {data, total} = await getPhysicalProductDetail(params)
      // console.log(data.good_pic, '什么东西')
      // data.good_pic = data.good_pic.split('|')
      data.good_sendinfo = JSON.parse(data.good_sendinfo)
      data.good_transport = JSON.parse(data.good_transport)
      data.good_transport.way = data.good_transport.way === '包邮'  ? 0 : 1
      data.good_price = Number(data.good_price)
      let tableData =  data.specifications
      let headerType = data.good_sendinfo.specificationType

      if (headerType) {
        headerType = headerType.split('|').map(item => {
          let newItem = item.split(',') //  ['title:颜色', 'subTitle:白色-黑色', 'titleKey:two']
          // 找到包含的字段抛出
          // 将找的字段进行截选抛出
          // 找出符合条件的字段进行字符串截取，获取值
          let title = newItem.find(item1 => item1.includes('title')).slice(newItem.find(item1 => item1.includes('title')).indexOf(':') + 1)
          let titleKey = newItem.find(item1 => item1.includes('titleKey')).slice(newItem.find(item1 => item1.includes('titleKey')).indexOf(':') + 1)
          let subTitle = newItem.find(item1 => item1.includes('subTitle')).slice(newItem.find(item1 => item1.includes('subTitle')).indexOf(':') + 1).split('-')
          return {
            subTitle,
            [titleKey]: title,
            value: title,
            key: titleKey
          }
        })
        tableData = tableData.map(item => {
          // 新增对象
          const newItem = {}
          Object.keys(item).forEach(key => {  // 表头字段
            // 将符合条件的进行赋值
            // 条件data2中对象的key === data1中对象的key，则返回的对象{ headerType[0][headerType[0][key]]: tableData[0][key]}
           headerType.forEach(item1 => { if (item1.key === key) newItem[item1[item1.key]] = item[key] })
          })
          return {
            ...newItem,
            '价格': item.price,
            '库存': item.inventory,
          }
        })
      } else {
        tableData = tableData.map(item => {
          return {
            '价格': item.price,
            '库存': item.inventory,
          }
        })
      }
      // else {
      //   headerType = []
      // }
      // console.log(headerType);
      // console.log(data);


      return { data, total, headerType, tableData }
    },

    // 修改实体和虚拟产品
    async uploadGoods({commit }, data) {
      let res = {}
      if (data.good_type === 1) {
        res = await updateVitualProduct(data)
      } else {
        res = await updatePhysicalProduct(data)
      }
      return res
    },

    // 下架产品（虚拟和实体）
    async deleteGoods({commit }, params) {
      let res = {}
      console.log(params, 'goods');
      if (params.good_type === 1) {
        res = await deleteVitualProduct({ id: params.id, type:params.typeKey })
      } else {
        res = await deletePhysicalProduct({ id: params.id, type:params.typeKey })
      }
      return res
    },

    // 获取甲方产品列表
    async getGoodsList({commit }, params) {
      let res = await getProductListForPartyA({ ...params })
      res.data = res.data && res.data.length ? res.data.map(item => {
        return {
          ...item,
          user: item.address.receiver,
          good_type: item.good_type,
          good_type_key: item.good_type == 2 ? "实体" : "虚拟",
          form: {
            orderNumber: item.express_num,
            id: item.id,
            userName: item.address.receiver,
            phone: item.address.contact,
            address: `${item.address.provincecode}-${item.address.citycode}-${item.address.address}`
          }
        }
      }) : []
      return res
    },

    // 填写订单号
    async uploadOrderNo({commit }, data) {
      return await createOrderInfo(data)
    },

  //  结束虚拟状态id
    async endOrder({ commit }, params) {
      return await endOrderApi(params)
    }
  },
}

export default goods;
