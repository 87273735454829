<template>
	<div class="detail banner">
		<!-- 商品标题点击跳转商品详情页 -->
		<div class="showArea">
			<div class="gooodPic">
				<img :src="baseUrl + goodInfo.good_pic" alt="" class="goodImg" />
			</div>
			<div class="goodInfo">
				<div class="right">
					<div class="titleCollect">
						<div class="title">标题：{{ goodInfo.good_title }}</div>
						<div class="collect">
							<i class="el-icon-star-off" @click="dialogVisible = true" />
						</div>
					</div>

					<div class="price common">价格：{{ goodInfo.good_price }}</div>

					<div
						class="common"
						v-for="(item, index) in goodInfo.headerType"
						:key="index">
						{{ item.value }}：
						<span
							v-for="(subItem, index) in item.subTitle"
							:key="index"
							class="subTitle tag"
							@click="dialogVisible = true"
							>{{ subItem }}</span
						>
					</div>

					<div class="common" v-if="$route.query.type === '2'">
						物流：{{ goodInfo?.good_sendinfo?.sendlogistics }}
					</div>

					<div class="common">月售：{{ goodInfo.good_salenum }}</div>

					<div class="common">库存：{{ goodInfo.good_inventory || 0 }}</div>
					<div class="common">
						运费：{{
							goodInfo.way ? "包邮" : goodInfo?.good_transport?.price + "元"
						}}
					</div>

					<div class="clickButton">
						<span class="button" @click="dialogVisible = true"> 点击购买</span>
					</div>
				</div>
			</div>
		</div>

		<el-dialog
			title="该功能暂时只能在APP使用"
			:visible.sync="dialogVisible"
			width="40%">
			<span>请扫描二维码下载APP</span>
			<div class="link">
				<div>
					<el-image
						:src="baseUrl + 'app2code/ios.png'"
						class="qrcode"></el-image>
					<div>IOS下载</div>
				</div>
				<div>
					<el-image
						:src="baseUrl + 'app2code/android.png'"
						class="qrcode"></el-image>
					<div>安卓下载</div>
				</div>
			</div>
			<div class="btns">
				<button class="custom_info" @click="dialogVisible = false">
					取 消
				</button>
				<el-button type="primary" @click="dialogVisible = false"
					>确 定</el-button
				>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	components: {},

	data() {
		return {
			dialogVisible: false,
			goodInfo: {},
			baseUrl: this.$store.state.baseUrl,
		};
	},
	computed: {},

	watch: {},

	methods: {
		async getGoodInfo() {
			try {
				const type = this.$route.query.type;
				const res =
					type == 1
						? await this.getVitualDetail()
						: await this.getPhysicalDetail();
				console.log(res, "详情");
				this.goodInfo = res.data;
				this.goodInfo.good_pic = this.goodInfo.good_pic[0];
				this.goodInfo.way = type == 1 ? "包邮" : this.goodInfo.way;
				// console.log(this.goodInfo);
			} catch (e) {
				//TODO handle the exception
				console.log(e, "获取详情出错");
				this.$message.error(res.msg, "获取详情出错");
			}
		},

		// 获取虚拟信息
		async getVitualDetail() {
			try {
				const res = await this.$store.dispatch("goods/getVitualDetail", {
					id: this.$route.query.good_uuid,
				});
				return res;
			} catch (e) {
				//TODO handle the exception
				console.log(e, "详情页虚拟信息出错~");
			}
		},

		// 获取实体信息
		async getPhysicalDetail() {
			try {
				const res = await this.$store.dispatch("goods/getPhysicalDetail", {
					id: this.$route.query.good_uuid,
				});
				res.data.headerType = res.headerType;
				return res;
			} catch (e) {
				//TODO handle the exception
				console.log(e, "详情页实体信息出错~");
			}
		},

		// 返回上一页
		goBack() {
			this.$router.go(-1);
		},

		// 跳转到购物车
		goCart() {
			this.$router.push({ path: "/cart" });
		},
	},

	created() {},

	async mounted() {
		await this.getGoodInfo();
	},
};
</script>
<style scoped lang="scss">
.detail {
	.showArea {
		display: flex;

		.gooodPic {
			flex: 1;
			// background-color: pink;
			padding: 20px;
			.goodImg {
				width: 100%;
				border-radius: 15px;
			}
		}
		.goodInfo {
			flex: 1;
			margin: 40px;
			background-color: #eee;
			border-radius: 15px;
			.right {
				padding: 20px;
				font-size: 20px;
				.titleCollect {
					display: flex;
					justify-content: space-between;
					.collect {
						cursor: pointer;
					}
				}
				.common {
					margin-top: 20px;
				}
				.clickButton {
					text-align: center;
					margin-top: 20px;
					.button {
						padding: 10px 20px;
						border-radius: 20px;
						background: linear-gradient(
							to right,
							#feadd4,
							#f286ef,
							#b375f7
						); /* 标准的语法 */
						color: #fff;
						cursor: pointer;
					}
				}
			}
		}
	}
	.tag {
		background-color: #f7f7fc;
		margin: 0 10px;
		padding: 5px;
		border-radius: 7px;
		color: #999;
		cursor: pointer;
	}
	.link {
		display: flex;
		text-align: center;
	}
}
::v-deep .btns {
	text-align: center;
	.custom_info {
		flex: 1;
		padding: 12px 20px;
		border: #6357ff 1px solid;
		color: #6357ff;
		background-color: #fff;
		border-radius: 4px;
		margin: 0 10px;
		cursor: pointer;

		:hover {
			background-color: #fff;
		}
	}
}

::v-deep .el-button--primary {
	flex: 1;
	margin-left: 15px;
	background-color: #6357ff;
	border: #6357ff 1px solid;
}
</style>
