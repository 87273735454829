import { service } from "@/utils/request";

// 用户实名认证
export const getRenZheng = async (params) => {
	return await service.get("/api/userinfo/authentication?token=" + params);
};

// 支付宝账号
export const getAlipay = async (params) => {
	return await service.get("/api/recharge/withdraw?token=" + params);
};

// 修改支付宝账号
export const updateAlipay = async (data) => {
	return await service({
		method: "post",
		url: "/api/recharge/edituser",
		data,
	});
};

// 记录用户日志操作
export const getLog = async (data) => {
	return await service({
		method: "post",
		url: "/api/userinfo/usersetlog",
		data,
	});
};

// 提现接口
// export const getCash = async(data) => {
//   return await service({
//     method: 'post',
//     url: '/api/recharge/withdraw',
//     data
//   })
// }
// 提现接口
export const getCash = async (params) => {
	return service({
		method: "get",
		url: "/showcase/recharge/showCaseRecharge",
		params,
	});
};

// 添加支付宝账号
export const createAlipay = async (data) => {
	return service({
		method: "post",
		url: "api/recharge/adduser",
		data,
	});
};

// 用户申请资料
export const getApply = async (params) => {
	return service(
		{
			method: "get",
			url: "/showcase/admin/regGetMyInfo",
			params,
		}
		// '/showcase/admin/regGetMyInfo'
	);
};

// 个人申请
export const getMyApply = async (data) => {
	return await service({
		method: "post",
		url: "/showcase/admin/reg",
		data,
	});
};

// 企业申请
export const getCompanyApply = async (data) => {
	return await service({
		method: "post",
		url: "/showcase/admin/qyReg",
		data,
	});
};

// 企业缴费
export const getCompanyPay = async (data) => {
	return await service({
		method: "post",
		url: "/showcase/admin/payDeposit",
		data,
	});
};

// 虚拟产品发布
export const createRelease = async (data) => {
	return await service({
		method: "post",
		url: "/showcase/good/creatVarGood",
		data,
	});
};

// 实体产品发布
export const createReleasePhysical = async (data) => {
	return await service({
		method: "post",
		url: "/showcase/good/creatEntityGood",
		data,
	});
};

// 获取虚拟产品列表
export const getVitualProductList = async (params) => {
	return await service({
		method: "get",
		url: "/showcase/good/varGoodList",
		params,
	});
};

// 获取实体商品列表
export const getPhysicalProductList = async (params) => {
	return await service({
		method: "get",
		url: "/showcase/good/EntityGoodList",
		params,
	});
};

// 获取虚拟产品详情
export const getVitualProductDetail = async (params) => {
	return await service({
		method: "get",
		url: "/showcase/good/varGoodInfo",
		params,
	});
};

// 获取实体产品详情
export const getPhysicalProductDetail = async (params) => {
	return await service({
		method: "get",
		url: "/showcase/good/EntityGoodInfo",
		params,
	});
};

// 更新虚拟产品
export const updateVitualProduct = async (data) => {
	return service({
		method: "post",
		url: "/showcase/good/editVarGood",
		data,
	});
};

// 更新实体产品
export const updatePhysicalProduct = async (data) => {
	return service({
		method: "post",
		url: "/showcase/good/editEntityGood",
		data,
	});
};

// 虚拟产品删除
export const deleteVitualProduct = async (params) => {
	return await service({
		method: "get",
		// url: '/showcase/good/varGoodDel',
		url: "/showcase/good/removeGood",
		params,
	});
};

// 实体产品删除
export const deletePhysicalProduct = async (params) => {
	return await service({
		method: "get",
		// url: '/showcase/good/removeGood',
		url: "/showcase/good/removeGood",
		params,
	});
};

//  后台产品列表
export const getProductList = async (params) => {
	return await service({
		method: "get",
		url: "/showcase/sale/saleList",
		params,
	});
};

// 撤回审核
export const getWithdrawAudit = async (params) => {
	return await service({
		method: "get",
		url: "/showcase/good/varGoodDel",
		params,
	});
};

// 结束虚拟订单
export const endOrderApi = async (params) => {
	return await service({
		url: "/showcase/Aftersale/exchangeSubmit",
		method: "get",
		params,
	});
};

//  获取收益中心接口列表
export const getProfitList = async (params) => {
	return await service({
		method: "get",
		url: "/showcase/recharge/saleRecharge",
		params,
	});
};

// 甲方获取产品列表
export const getProductListForPartyA = async (params) => {
	return await service({
		url: "/showcase/order/payeeOrderList",
		method: "get",
		params,
	});
};

// 填写物流订单号
export const createOrderInfo = async (data) => {
	return await service({
		url: "/showcase/order/payeeOrderList",
		method: "post",
		data,
	});
};

// 商家售后列表
export const getAfterSaleList = async (params) => {
	return await service({
		method: "get",
		url: "/showcase/Aftersale/afterSaleList",
		params,
	});
};

// 商家同意退款
/**
 * @param {*orderId, address} params
 * @returns
 */
export const agreeRefund = async (params) => {
	return service({
		method: "get",
		url: "/showcase/Aftersale/agree",
		params,
	});
};

// 商家拒绝退款
export const refuseRefund = async (data) => {
	return service({
		method: "post",
		url: "/showcase/Aftersale/refuseSale",
		data,
	});
};

// 商家签收退货退款
// export const agreeReturn = async(params) => {
//   return await service({
//     method: 'get',
//     url: '/showcase/Aftersale/payeeConfirm',
//     params
//   })
// }

// 获取地址列表
export const getAddressList = async (params) => {
	return await service({
		method: "get",
		url: "/showcase/admin/address",
		params,
	});
};

// 售后商家填写快递单号
// export const createAfterSaleInfo = async(data) => {
//   return await service({
//     method: 'post',
//     url: '/showcase/Aftersale/payeeConfirm',
//     data
//   })
// }

// 商家确认收货
// export const confirmReceive = async(params) => {
//   return await service({
//     method: 'get',
//     url: '/showcase/Aftersale/payeeConfirm',
//     params
//   })
// }

// 商家换货
export const changeGoods = async (data) => {
	return await service({
		method: "post",
		// url: '/showcase/Aftersale/payOrderSubmit',
		url: "/showcase/Aftersale/payeeOrderSubmit",
		data,
	});
};

// 评论列表
export const getCommentList = async (params) => {
	return await service({
		method: "get",
		url: "/showcase/good/goodAssess",
		params,
	});
};

// 回复评论
export const replyComment = async (data) => {
	return await service({
		method: "post",
		url: "/showcase/good/replyGoodAssess",
		data,
	});
};

// 系统通知
export const getNotice = async (params) => {
	return await service({
		method: "get",
		url: "/api/message/notices",
		params,
	});
};

// 获取系统通知数量
export const getNoticeCount = async (params) => {
	return await service({
		method: "get",
		url: "/api/message/getnum",
		params,
	});
};

export const getUser = async (data) => {
	return await service.post("/api/login/getmyinfo", data);
};

// 商家收货地址
export const createAddress = async (data) => {
	return await service.post("/showcase/admin/address", data);
};

// 删除收货地址
export const deleteAddress = async (params) => {
	console.log(params);
	// const res = await service.get('/showcase/admin/addressDel', params)
	return await service({
		method: "get",
		url: "/showcase/admin/addressDel",
		params,
	});
};

export const getBaseUrl = async () => {
	// const res = await service.get('/showcase/admin/addressDel', params)
	return service({
		method: "get",
		url: "/api/ImageUpload/getImageHeader",
	});
};

// import {service} from "@/utils/request";
export const uoloadFn = (data) =>
	service.post("/api/base/upload", data, {
		headers: { "Content-Type": "multipart/form-data" },
	});
export const uoloadZipFn = (data) =>
	service.post("/api/base/uploadjustzipfile", data, {
		headers: { "Content-Type": "multipart/form-data" },
	});
