import {
    agreeRefund,
    changeGoods,
    getAddressList,
    getAfterSaleList,
    getCommentList,
    refuseRefund,
    replyComment
} from '@/api'
// getWithdraw

const afterSale = {
    namespaced: true,
    state: {},

    mutations: {},

    actions: {
        // 获取售后列表
        async getAfterSaleListApi({commit}, params) {
            const res = await getAfterSaleList(params)
            let newRes = res
            const appealType = {
                1: { //退款
                    0: '待退款',
                    1: '已退款',
                    2: '拒绝退款',
                },
                2: { //'退货退款',
                    0: '待退货退款',
                    1: '已退货退款',
                    2: '拒绝退货退款',
                },
                3: { //'换货',
                    0: '待换货',
                    1: '已换货',
                    2: '拒绝换货',
                }
            }
            newRes.data = res.data ? res.data.map(item => {
                item.type = appealType[item.afterSaletype][item.state]
                item.receivinfo = item.receivinfo || '无'
                return item
            }) : []
            return newRes
        },

        // 操作
        /**
         * params.key:{
         *  agreeRefund: 同意退款
         *  refuseRefund: 拒绝退款
         *  agreeReturn: 同意退货退款
         *  refuseReturn: 拒绝退货退款
         *  agreeExchangeGoods： 同意换货
         *  refuseExchangeGoods：拒绝换货
         *
         * }
         */
        async actions({commit}, params) {
            /**
             * 根据isValue判断是否有value的参数
             * 所有的拒绝接口都需要原因也就是value
             * 同意接口走的都是相同的接口 只有同意退货和换货需要地址id
             */
            const {id, value, isValue, key, order, address} = params
            let res = null
            if (isValue && key !== 'agreeExchangeGoods') {
                if (key === 'changeGoods') {
                    // 填写订单号
                    res = await changeGoods({order, express_order: value})
                } else {
                    res = !!address
                        // 同意退货
                        ? await agreeRefund({orderId: id})
                        // 所有拒绝接口
                        : await refuseRefund({orderId: id, reason: value})
                }

            } else if (isValue && key === 'agreeExchangeGoods') {
                //  同意换货
                res = await agreeRefund({orderId: id, address})
            } else {
                // 同意退款
                res = await agreeRefund({orderId: id})
            }
            return res
        },

        // 获取地址接口
        async getAddressListApi({commit}, params) {
            return await getAddressList(params)
        },

        // 评论列表
        async getCommentListApi({commit}, params) {
            let res = await getCommentList(params)
            res.data = res.data ? res?.data.map(item => {
                item.receivreason = item.text
                item.receivinfo = item.text
                item.auditingType = !item.isread ? '已读' : '未读'
                return item
            }) : []
            return res
        },

        // 回复评论
        async replyCommentApi({commit}, data) {
            return await replyComment(data)
        }

    },
}

export default afterSale
