import Vuex from 'vuex'
import Vue from 'vue'
import {getRenZheng, getUser, getApply, getBaseUrl} from '@/api/index';
import user from './modules/user';
import goods from './modules/goods';
import income from './modules/income';
import afterSale from './modules/afterSale';

import getters from './getters'

Vue.use(Vuex)
const modules = {}
const files = require.context('./modules', false, /\.js$/)
files.keys().forEach((key) => {
  modules[key.replace(/(modules|\/|\.|js)/g, '')] = {
    ...files(key).default,
    namespaced: true,
  }
})

export default new Vuex.Store({
  state: {
    baseUrl: '',
    loading: false,
    userInfo: {},
    my: {},
    token: '',
    BaseImgUrl:'',
    tongfanUrl:'https://chuobuy.com/',
    buttonLoading:false,
    pageLoading:false
  },
  mutations: {
    changeLoading(state, payload) {
      state.loading = payload
    },

    SET_BASEURL(state, payload) {
      state.baseUrl = payload
    },

    SET_TOKEN(state, payload) {
      state.token = payload
      localStorage.setItem('token', JSON.stringify(payload))
    },

    SET_USERINFO(state, payload) {
      payload.icon = payload.icon[0]
      state.userInfo = payload
      localStorage.setItem('tongfanstate', JSON.stringify(payload.tongfanstate))
    },

    SET_MY(state, payload) {
      state.my = payload
      localStorage.setItem('myrenzheng', JSON.stringify(payload.myrenzheng))
    },

    SET_STATE(state, payload) {
      state.isApply = payload
      const tongfanstate = localStorage.getItem('tongfanstate')
      let state_payload = tongfanstate == 2 ? 2 : payload.personal.state
      localStorage.setItem('isApplyMsg', JSON.stringify(state_payload))
      // localStorage.setItem('isApplyMsg', JSON.stringify(2))
    },

    BASEIMGURL(state,item){
      state.BaseImgUrl=item
    },
    SET_BUTLAODING(state,bool){
      state.buttonLoading=bool
    },
    SET_PAGLOADING(state,bool){
      state.pageLoading=bool
    }
  },

  actions: {
    async getBaseUrl({commit}, payload) {
      try {
        const res = await getBaseUrl()
        commit('SET_BASEURL', res.data.path)
      } catch (e) {
        console.log(e)
      }
    },

    async getUserInfo({commit}, token) {
      try{
        commit('SET_TOKEN', token)
        const {info, my} = await getRenZheng(token)
        commit('SET_MY', my)
        const res = await getUser({token})
        commit('SET_USERINFO', res)
        const params = {
          token
        }
        // 是否申请
        const {data} = await getApply(params)
        commit('SET_STATE', data)
      }catch(e){
        //TODO handle the exception
        console.log(e, '获取用户信息出错了~');
      }
    }
  },

  getters,

  modules: {
    user,
    goods,
    income,
    afterSale
  }
})
