<template>
  <div class='appeal container-global-color container-global-table'>
    <div class="filter-class">
      <top-button :button_list="radios" @ok="handleChangeRadio" :active="radio" />
      <el-date-picker
          class="date-picker"
          v-model="selectTime"
          @change="handleChange"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
      </el-date-picker>
    </div>

    <component :is="'appealTable'"  
      :fileKey="fileKey"  
      :tableHeaders="tableHeaders" 
      :tableData="tableData"
      @action="handleComponentsActions"
       />

    <el-pagination
    ref="elel"
      class="pagination"
      :page-size="params.size"
      layout="prev, pager, next"
      :current-page.sync="params.page"
      @current-change="handleCurrentChange"
      :total="total">
    </el-pagination>
    

    <el-dialog
      :title="elDialogData.dialogTitle + '内容'"
      :visible.sync="elDialogData.dialogVisible"
      width="30%"
      @close="elDialogData.dialogVisible = false"
    >
      <el-input v-model="elDialogData.value" :placeholder="`请输入${elDialogData.dialogTitle}的内容`"></el-input>
      <template slot="footer">
        <el-button @click="elDialogData.dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit()">提交</el-button>
      </template>
    </el-dialog>

  </div>
</template>
<script>
import { appealTable, TopButton } from '../components'

export default {
  components: {
    appealTable,
    TopButton
  },
  data() {
    return {
      elDialogData: {
        key: '',
        dialogVisible: false,
        dialogTitle: '',
        value: '',
      },
      
      radios: [
        { label: 1, name: '待回复' },
        { label: 2, name: '已回复' },
      ],

      radio: 1,

      tableHeaders: [
        { id: 'goodTitle', name: '商品名称' },
        // { id: 'receivprice', name: '订单金额' },
        { id: 'creat_time', name: '时间' },
        { id: 'text', name: '内容' },
        { id: 'auditingType', name: '状态' },
        { id: 'fromUser', name: '用户' },
      ],
      tableData: [],

      fileKey: 'reComment',

      selectTime: ['',''],
      params: {
        page: 1,
        size: 10,
        startTime: '',
        endTime: '',
        good_type: '1',
        accordTo: "user"
      },
      total: 1,
      componentsName: 'appealTable'
    };
  },

  computed: {},

  watch: {},

  methods: {
    
    // 打开弹窗
    // handleOrderDialog(type) {
    //   this.elDialogData.key = type
    //   const titleType = {
    //     'order': '修改物流单号',
    //   }
    //   this.elDialogData.dialogTitle = titleType[type]
    //   this.elDialogData.dialogVisible = true
    // },
    
    // 弹窗确认按钮
    async handleSubmit() {
      try{
        console.log(this.elDialogData, 'elDialogData');
        const { id, value } = this.elDialogData
        const res = await this.$store.dispatch('afterSale/replyCommentApi', {comment_id: id, content: value})
        if(res.code === 200) {
          this.$message.success('回复成功')
        } else {
          this.$message.error('回复失败')
        }
        this.elDialogData.dialogVisible = false
        this.elDialogData.value = ''
      }catch(e){
        //TODO handle the exception
        console.log('回复接口出错了~');
      }
    },

    // 获取数据列表
    async getList() {
      try{
        const res = await this.$store.dispatch('afterSale/getCommentListApi', this.params)
        console.log(res, '获取评论列表');
        this.tableData = res.data
        // this.total = res.total  
      }catch(e){
        //TODO handle the exception
        console.log(e, '获取评论列表失败');
      }
    },

    // 页面操作
    async handleComponentsActions(params) {
      // const { key, dialogTitle, id} = params
      this.elDialogData = {
        ...this.elDialogData,
        // key,
        // dialogTitle,
        // // dialogVisible: true,
        // id,
        ...params

      }
    },

    // 时间筛选
    async handleChange(val) {
      console.log(val);
      console.log(this.selectTime);
      if(!this.selectTime){
        this.selectTime = ['', '']
      }
      this.params.startTime = this.selectTime[0]
      this.params.endTime = this.selectTime[1]
      await this.getList()
    },

    // 选择类型
    async handleChangeRadio(val) {
      this.params.good_type = val
      this.radio = val
      const componentsType = {
        '1': 'appealTable',
        '2': 'CommentTable',
      }
      this.componentsName = componentsType[val]
      await this.getList()
    },
    
    // 分页筛选
    async handleCurrentChange(val) {
      this.params.page = val
      await this.getList()
    },

  },

  created() {

  },

  async mounted() {
    await this.getList()
  },
}
</script>
<style scoped lang="scss">
.appeal {
  .filter-class {
    display: flex;
    .date-picker {
      margin-left: 15px;
    }
  }
  .actions {
    cursor: pointer;
    color: red
  }
  .middle {
    margin: 0 10px;
  }
  .text-ellipsis {
    cursor: pointer;
    color: #8690ee;
  }
  ::v-deep .pagination {
    text-align: right;
    padding-top: 20px;
    .el-pager li.active {
      color: #6357ff;
      cursor: default;
    }
  }
}
</style>