<template>
	<div class="add-labels">
		<div :class="['label-input', { 'is-focus': isFocus }]">
			<el-tag
				v-for="(label, index) in labelList"
				:key="index"
				closable
				type="info"
				@close="handleClose(label)"
				>{{ label }}</el-tag
			>
			<el-input
				v-model="name"
				placeholder="请输入商品标签"
				suffix-icon="el-icon-date"
				@focus="handleFocus"
				@blur="handleblur" />
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			name: "",
			form: {
				name: "",
			},
			labelList: [],
			isFocus: false,
		};
	},
	computed: {
		content() {
			return this.labelList.length + "/5";
		},
	},

	methods: {
		show(val) {
			this.labelList = val;
		},
		handleblur() {
			this.isFocus = false;
			if (!this.name.trim()) return;
			if (this.labelList.length >= 5) return this.$message.error("标签已满");
			if (this.labelList.includes(this.name))
				return this.$message.error("标签已存在");
			this.labelList.push(this.name);
			this.$emit("ok", this.labelList);
			const res = document.querySelectorAll(".el-icon-date")[1];
			res.dataset.customAttribute = this.labelList.length + "/5";
			this.name = "";
		},

		handleClose(tag) {
			this.labelList.splice(this.labelList.indexOf(tag), 1);
			this.$emit("ok", this.labelList);
		},

		handleFocus() {
			this.isFocus = true;
		},
	},
	created() {},
	mounted() {},
};
</script>
<style scoped lang="scss">
::v-deep .is-focus {
	border: 1px solid #6357ff;
	.el-input__inner {
		border: none;
	}
}
.add-labels {
	.label-input {
		display: flex;

		.el-icon-plus {
			cursor: pointer;
			color: aqua;
		}
		.el-tag {
			margin: 0 5px;
			border: none;
		}
	}
	.el-tag {
		height: 40px;
		padding: 6px 10px;
	}
}
::v-deep .el-icon-date:before {
	content: attr(data-custom-attribute);
}
</style>
