const validateFuntion = (rule, value, callback) => {
  const ruleType = {
    userCount: '用户名',
    passWord: '密码',
    title: '标题',
    price: '价格',
    postage: '运费',
    sendTiem: '发货时间',
    Contact: '客户联系方式',
    logistics: '发货物流',
    cardNumber: '银行卡',
    url: '百度网盘地址',
    pwd: '百度网盘提取码',
  }
  if (!value) {
    callback(new Error(( ruleType[rule.field] || '') +'不能为空'));
  } else {
    // 输入数字
    const numberTypes = ['price']
    if (numberTypes.some(item => item === rule.field)) {
      if (!/^[0-9]+(\.[0-9]{1,2})?$/.test(value)) {
        callback(new Error(( ruleType[rule.field] || '') +'必须为数字'))
      }
    }
    // 银行卡校验
    // if (rule.field === 'cardNumber') {
    //   if ( !/^(?:\d{16}|\d{19})$/g.test(value)) {
    //     callback(new Error(( '请填写正确' + ruleType[rule.field] || '') ))
    //   }
    //   // 银行卡格式校验
    //   let arrNumber = value.split('')
    //   let num = 0
    //   arrNumber.forEach((item, index) => {
    //     if (index && index % 4 == 0) {
    //       arrNumber.splice(index + (num++), 0, ' ')
    //     }
    //   }) 
    //   value = arrNumber.join('')
    // }
    callback()
  }
}

export const rules = {
  userCount: [{ required: true, validator: validateFuntion, trigger: 'blur' }],
  passWord: [{ required: true, validator: validateFuntion, trigger: 'blur' }],
  title: [{ validator: validateFuntion, trigger: 'blur'}],
  postage: [{ validator: validateFuntion, trigger: 'blur'}],
  price: [{ validator: validateFuntion, trigger: 'blur'}],
  goodPic: [ { required: true,  validator: validateFuntion, trigger: 'blur'}],
  radio: [{ required: true,  validator: validateFuntion, trigger: 'blur'}],
  sendTiem: [{ validator: validateFuntion, trigger: 'blur'}],
  Contact: [{ validator: validateFuntion, trigger: 'blur'}],
  logistics: [{ validator: validateFuntion, trigger: 'blur'}],
  alllogistics: [{ validator: validateFuntion, trigger: 'blur'}],
  url: [{ validator: validateFuntion, trigger: 'blur'}],
  pwd: [{ validator: validateFuntion, trigger: 'blur'}]
}

// 个人橱窗申请
export const applyFormRules = {
  name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
  region: [{ required: true, message: '请输入身份证号', trigger: 'blur' }],
  face: [{ required: true, message: '请上传身份证正面', trigger: 'blur' }],
  back: [{ required: true, message: '请上传身份证反面', trigger: 'blur' }],
  // cardNumber: [{ required: true, message: '请输入银行卡号', trigger: 'blur' }],
  cardNumber: [{ validator: validateFuntion, trigger: 'change' }],
  cardIssuer: [{ required: true, message: '请输入开户银行', trigger: 'blur' }],
  phone: [{ required: true,message: '请输入手机号', trigger: 'blur' }],
  checked: [{ required: true,message: '请阅读并同意协议', trigger: 'change' }]
}

// 企业申请橱窗
export const enterpriseFormRules = {
  name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
  // region: [{ required: true, message: '请输入身份证号', trigger: 'blur' }],
  face: [{ required: true, message: '请上传身份证正面', trigger: 'blur' }],
  back: [{ required: true, message: '请上传身份证反面', trigger: 'blur' }],
  license: [{ required: true, message: '请上传营业执照', trigger: 'blur' }],
  otherLicense: [{ required: true, message: '请上传申请书', trigger: 'blur' }],
  acount: [{ required: true,message: '请输入收款账号', trigger: 'blur' }],
  cardIssuer: [{ required: true, message: '请输入开户银行', trigger: 'blur' }],
  socialCode: [{ required: true,message: '请输入统一社会信用代码', trigger: 'change' }],
  manager: [{ required: true,message: '请输入运营人姓名', trigger: 'change' }],
  iphone: [{ required: true,message: '请输入联系电话', trigger: 'change' }],
  email: [{ required: true,message: '请输入邮箱', trigger: 'change' }],
  enterprise_idcard: [{ required: true,message: '请输入企业运营人身份证号', trigger: 'change' }]
}

// 支付宝账号
export const AliForm = {
  account: [{ required: true, message: '支付宝账号', trigger: 'blur' }],
  name: [{ required: true, message: '认证名称（支付宝个人真实姓名）', trigger: 'blur' }],
}

// 支付宝提现
export const AliWithdrawForm = {
  price: [{ required: true, message: '提现金额', trigger: 'blur' }],
  pwd: [{ required: true, message: '支付密码', trigger: 'blur' }],
  account: [{ required: true, message: '提现账户', trigger: 'blur' }],
}