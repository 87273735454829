import {getProfitList, getAlipay, updateAlipay, getLog, getCash, createAlipay} from '@/api'
// getWithdraw

const inCome = {
  namespaced: true,
  state: {},

  mutations: {},

  actions: {
    // 获取收益
    async getInComeInfo ({ commit }, params) {
      const res = await getProfitList(params)
      return res
    },

    // 获取支付宝账号
    async getAlipayInfo ({ commit }, params) {
      return await getAlipay(params)
    },

    // 修改/添加 支付宝账号
    async updateAlipayInfo ({ commit }, params) {
      let res = {}
      params.token = JSON.parse(localStorage.getItem('token'))
      if(params.id) {
        res = await updateAlipay(params)
      } else {
        res = await createAlipay(params)
      }
      return res
    },

    // 获取提现信息
    // async getWithdrawInCome ({ commit }, params) {
    //   const res = await getWithdraw(params)
    // },

    // 记录提现操作
    async getLogInCome ({ commit }, data) {
      return await getLog(data)
    },

    // 提现
    async withdrawInCome ({ commit }, params) {
      return await getCash(params)
    }
  },
}

export default inCome