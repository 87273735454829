<template>
  <div class="appeal-table">
    <el-table
      :data="tableData"
      :stripe="true"
      border
      :header-cell-style="{ background: '#fafafa' }"
      style="width: 100%">

      <el-table-column
        v-for="tabelHeader in tableHeaders"
        :key="tabelHeader.id"
        :prop="tabelHeader.id"
        :label="tabelHeader.name"
        align="center"
      >
      <template slot-scope="scope">
        <el-popover
          v-if="tabelHeader.name === '内容'"
          placement="right"
          width="400"
          trigger="click">
          <p> {{ scope.row.receivreason }} </p>
          <span slot="reference"
            :class="['text-ellipsis']"
          >{{ scope.row.receivinfo.length > 10
           ? scope.row.receivinfo.slice(0,9) + '...'
           : scope.row.receivinfo
           }}</span>
        </el-popover>
        <span v-else-if="tabelHeader.name === '类型'"> {{ commentType[scope.row.type] }}</span>
        <span v-else> {{ scope.row[[tabelHeader.id]] }}</span>
      </template>
      </el-table-column>

      <el-table-column
        prop="action"
        label="操作"
        align="center"
        width="320px"
      >
        <template slot-scope="scope">
          <span>
            <el-button
                v-if="fliterActions(scope.row)[0].key !== 'changeGoods'"
								class="table-actions"
                @click = "handleActions(fliterActions(scope.row)[0], scope.row)"
                :disabled="!!scope.row.state" >{{ fliterActions(scope.row)[0].name }}</el-button>
            <el-button
                v-if="fliterActions(scope.row)[0].key == 'changeGoods'"
								class="table-actions"
                @click = "handleActions(fliterActions(scope.row)[0], scope.row)"
                :disabled="scope.row.sale_state !== 3" >{{ fliterActions(scope.row)[0].name }}</el-button>
            <span v-for="(item) in fliterActions(scope.row).filter(item => item.key != fliterActions(scope.row)[0].key)" :key="item.key">
              <span class="middle">|</span>
              <el-button

								class="table-actions"
                  @click = "handleActions(item, scope.row)"
                  :disabled="!!scope.row.state"
              >{{ item.name }}</el-button>
            </span>
          </span>
        </template>
      </el-table-column>

    </el-table>

    <el-dialog
        :title="'请填写您的收货地址'"
        :visible.sync="elDialogData.dialogVisible"
        width="30%"
        @close="elDialogData.dialogVisible = false"
    >
      <el-input v-model="elDialogData.receiver" :placeholder="`请填写收件人`"  class="addressInfo"></el-input>
      <el-input v-model="elDialogData.contact" :placeholder="`请填写您的收件手机号`" class="addressInfo" ></el-input>
      <el-input v-model="elDialogData.provincecode" :placeholder="`请填写您的省份`" class="addressInfo" ></el-input>
      <el-input v-model="elDialogData.citycode" :placeholder="`请填写您所在的市`" class="addressInfo" ></el-input>
      <el-input v-model="elDialogData.countycode" :placeholder="`请填写您所在的区`" class="addressInfo" ></el-input>
      <el-input v-model="elDialogData.address" :placeholder="`请填写你的具体位置`" class="addressInfo" ></el-input>
      <template slot="footer">
        <el-button @click="elDialogData.dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit()">提交</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {

    tableData: {
      type: Array,
      default: () => []
    },

    tableHeaders: {
      type: Array,
      default: () => []
    },

    fileKey: String
  },
  data () {
    return {
      commentType: 'AppealTable',
      elDialogData: {
        address: '',
        dialogVisible: false,
        countycode: '',
        citycode: '',
        provincecode: '',
        contact: '',
        receiver: '',
      }
    }
  },

  methods: {
    async handleActions(item, row) {
      console.log(item)
      const res = await this.$store.dispatch('afterSale/getAddressListApi')
      // 添加地址
      if (item.key == 'address') {
        this.elDialogData.dialogVisible = true
        this.elDialogData.item = item
        this.elDialogData.row = row
      } else if (item.key === 'updateGoodInfo') {
        // 虚拟商品修改
        this.$router.push({
          path: '/',
          query: { good_uuid: row.good_info.good_uuid, type: row.good_info.good_type }
        })
      } else if (item.key === 'endOrder') {
        // 换货结束
        const res = await this.$store.dispatch('goods/endOrder', {orderId: row.id})
        if(res.code == 200) {
          this.$message.success(res.msg)
        } else {
          this.$message.warning(res.msg)
        }
        this.$emit('updateList')
      } else if (item.key == 'agreeReturn' && !res.data) {
        // 判断是否有选择地址
        this.$message.warning('请先添加收货地址')
      } else {
        // 其他操作
        const params = {
          key:item.key || '',
          dialogTitle: item.name || '',
          id: row.id || '',
          dialogVisible: true,
          order: row.order_num || '',
          // express_order: row.pay_order_num || ''
        }
        this.$emit('action', params)
      }
    },

    // 提交收货商家收货地址
    async handleSubmit() {
      const isEmpty = Object.values(this.elDialogData).every(item => !!item)
      if(isEmpty) {
        const data = {
          // ...this.elDialogData,
          address: this.elDialogData.address,
          countycode: this.elDialogData.countycode,
          citycode: this.elDialogData.citycode,
          provincecode: this.elDialogData.provincecode,
          contact: this.elDialogData.contact,
          receiver: this.elDialogData.receiver,
          isdefault: 1
        }
        const res = await this.$store.dispatch('user/createAddressApi', data)
        if (res.code == 200) {
          this.$emit('updateAddressList')
          this.$message.success(res.msg)
          this.elDialogData.dialogVisible = false

        } else {
          this.$message.error(res.msg)
        }
      } else {
        this.$message.warning('请将您的收货信息填写完整')
      }
    },

    fliterActions(row) {
      // 操作类型
      let actionsType = {
        'reComment': {
          actions: [
            { key: 'reComment', name: '回复' }
          ],
        },
        'appeal': {
          actions: [
            {
              key: '1',
              name: '退款' ,
              resActions: [
                { name: '同意退款' , key: 'agreeRefund' },
                { name: '拒绝退款' , key: 'refuseRefund' },
              ]
            },
            {
              key: '2',
              name: '退货退款',
              resActions: [
                { name: '同意退货退款' , key: 'agreeReturn' },
                { name: '拒绝退货退款' , key: 'refuseReturn' },
                { name:  '添加地址', key: 'address'}
              ]
            },
            {
              key: '3',
              name: '换货',
              resActions: [
                { name: '同意换货' , key: 'agreeExchangeGoods' },
                { name: '拒绝换货' , key: 'refuseExchangeGoods' },
                { name:  '添加地址', key: 'address'}
              ]
            }
          ]
        }
      }
      // 筛选所要进行的操作
      // row.afterSaletype
      let allActions = actionsType['reComment']?.actions || []
      if (this.fileKey == 'appeal') {
        allActions = actionsType[this.fileKey].actions.filter(item => item.key == row.afterSaletype)
        // 判断是否是虚拟商品
        if(row.good_info.good_type == 1 && allActions[0].key == 3) {
          allActions[0].resActions = [
            { name: '修改商品信息', key: 'updateGoodInfo'},
            { name: '结束当前订单', key: 'endOrder'}
          ]
        }
       if(allActions[0].name == '换货' && row.sale_state == 3) {
         allActions[0].resActions = [
           { name: '填写换货订单号', key: 'changeGoods'}
         ]
       }
        return allActions[0].resActions
      }
      return allActions
    }
  }
}
</script>

<style lang="scss" scoped>
.appeal-table {
  .actions {
    cursor: pointer;
    border: none;
    color: red
  }
  .middle {
    margin: 0 10px;
  }
  .text-ellipsis {
    cursor: pointer;
    color: #8690ee;
  }
  .addressInfo {
    margin: 10px 0;
  }
  .el-button--default {
    padding: 0;
  }
}
</style>
