<template>
  <div class='apply-page banner' >
    <div class="apply-title">
      <h1>个人橱窗申请</h1>
      <span>本页面所收集的身份信息仅用作橱窗申请，请如实填写</span>
    </div>
    <el-form ref="form" :model="form" label-width="150px" :rules="rules">
      <el-form-item label="姓名" prop="name">
        <el-input
          v-model="form.name"
          placeholder="姓名"
          @focus="handleFocu('name')"
          :class="[active === 'name' ? 'active' : '', 'subInput']"
        ></el-input>
      </el-form-item>

      <el-form-item label="身份证号" prop="region">
        <el-input
          v-model="form.region"
          placeholder="身份证号"
          @focus="handleFocu('region')"
          :class="[active === 'region' ? 'active' : '', 'subInput']"
        ></el-input>
      </el-form-item>

      <el-form-item label="手机号" prop="phone">
        <el-input
          v-model="form.phone"
          placeholder="手机号"
          @focus="handleFocu('phone')"
          :class="[active === 'phone' ? 'active' : '', 'subInput']"
        ></el-input>
      </el-form-item>

      <div class="test-upload subInput">
        <el-form-item label="证件上传" prop="face">
          <el-upload
            action="/api/base/upload"
            list-type="picture-card"
            :before-upload="(flie => {beforeAvatarUpload('face', flie)})"
            :on-preview="handlePictureCardPreview"
            :on-success="(file) => {handleAvatarSuccess('face', file)}"
            :class="[!!form.face ? 'hide' : 'show']"
            :on-remove="(file) => {handleRemove('face', file)}">
            <i class="el-icon-circle-plus-outline upload-content" >
              <span>上传身份证正面</span>
            </i>
          </el-upload>
        </el-form-item>
        <el-form-item prop="back" class="back">
          <el-upload
            action="/api/base/upload"
            list-type="picture-card"
            :before-upload="(flie => {beforeAvatarUpload('back', flie)})"
            :on-preview="handlePictureCardPreview"
            :on-success="(file) => {handleAvatarSuccess('back', file)}"
            :class="[!!form.back ? 'hide' : 'show']"
            :on-remove="(file) => {handleRemove('back', file)}">
            <i class="el-icon-circle-plus-outline upload-content">
              <span>上传身份证反面</span>
            </i>
          </el-upload>
        </el-form-item>

      </div>
      <div class="tips">
        <div>提示： 请上传边框完整、图像清晰的图片，个人资料部分清晰可见，请勿作任何修改。</div>
        <div>支持jpg/png格式，单个文件大小不得超过5M</div>
      </div>

      <el-form-item label="银行卡号" prop="cardNumber" class="cardNumber">
        <el-input
          v-model="form.cardNumber"
          placeholder="银行卡号，请与上述实名认证保持一致"
          @focus="handleFocu('cardNumber')"
          @input="regCardNumber"
          :class="[active === 'cardNumber' ? 'active' : '', 'subInput']"
        ></el-input>
      </el-form-item>

      <el-form-item label="开户银行" prop="cardIssuer">
        <el-input
          v-model="form.cardIssuer"
          placeholder="开户银行"
          @focus="handleFocu('cardIssuer')"
          :class="[active === 'cardIssuer' ? 'active' : '', 'subInput']"
        ></el-input>
      </el-form-item>

      <el-form-item prop="checked">
        <el-checkbox v-model="form.checked" style="margin-left: 10px;"/>
        点击申请即表示同意<a href="#/Resign" style="color:#6357ff">《触圈橱窗注册协议》</a>协议且愿意遵守该协议中规定事项
      </el-form-item>

      <el-form-item  class="submit-btn subInput">
        <el-button round @click="submitForm()">提交申请</el-button>
      </el-form-item>
    </el-form>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { applyFormRules } from '@/utils/validate'
export default {
  components: {},
  data() {
    return {
      rules: applyFormRules,

      form: {
        name: '',
        region: '',
        phone: '',
        face: '',
        back: '',
        cardNumber: '',
        cardIssuer: '',
        checked: false
      },
      active: '',
      baseUrl: this.$store.state.baseUrl,

      dialogImageUrl: '',
      dialogVisible: false,

      regCardNumbers: ''
    };
  },

  computed: {},

  watch: {},

  methods: {
    beforeAvatarUpload(type, file) {
      this.isLoading(true)
      // 判断类型
      const fileType = file.type === 'image/jpeg' || 'image/png'
      if (!fileType) return this.$message.error('上传图片只能是 JPG/PNG 格式!')
      // 小于5M
      const isLt5M = file.size / 1024 / 1024 < 5
      if(!isLt5M) return this.$message.error('上传图片大小不能超过 5MB!')
    },

    handleRemove(type, file) {
      this.form[type] = ''
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleAvatarSuccess(key, file) {
      this.isLoading(false)
      this.form[key] = file.msg;
    },

    handleFocu(key) {
      this.active = key
    },

    // 整理格式
    regCardNumber () {
      this.form.cardNumber = this.form.cardNumber.replace(/\s/g, '').replace(/[^\d]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ');
      this.regCardNumbers = this.form.cardNumber.replace(/\s/g, '')
    },

    async submitForm() {
      try{
        if (!this.form.checked) return this.$message.error('请先同意用户协议!')
        this.$refs.form.validate(async valid => {
          console.log(this.form);
          if(valid) {
            console.log(this.form);
            const form = {
              enter_name: this.form.name,
              user_idcard: this.form.region,
              idcard_front: this.form.face,
              idcard_contrary: this.form.back,
              tel: this.form.phone,
              bank_deposit: this.form.cardIssuer,
              bank_card: this.regCardNumbers,
            }
            const {code, msg} = await this.$store.dispatch('user/getApply', form)
            if(code == 200) {
              this.$message.success(msg)
              // 重新获取权限进行本地进行存储
              const token = JSON.parse(localStorage.getItem('token'))
              await this.$store.dispatch('getUserInfo', token)
              // location.reload()
              await this.$router.push('/isApplying')
            } else {
              this.$message.error(msg)
            }
          }
        })
      }catch(e){
        //TODO handle the exception
        console.log(e, '提交出问题啦~');
      }
    }
  },
  created() {

  },
  mounted() {

  },

}
</script>
<style scoped lang="scss">
.apply-page {
  .test-upload {
    display: flex;
    flex-wrap: wrap;
  }

  .tips {
    margin: 10px 0 20px 200px;
    color: #cacaca;
    text-shadow: 0 10px 10px #d8d8d8;
  }
  .apply-title {
    text-align: center;
    margin-bottom: 20px;
    h1 {
      margin-top: 0;
    }
    span {
      color: #d7d7db;
    }
  }

  .subInput {
    width: 80%;
  }
  .upload-imag {
    display: flex;
    justify-content: space-around;
  }



  .upload-success {
    :hover {
      cursor: pointer;
    }
  }

  .submit-btn {
    text-align: center;
  }
}


::v-deep .el-upload--picture-card{
  width: 280px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .upload-content {
    display: flex;
    flex-direction: column;
    span {
      font-size: 16px;
      margin-top: 5px;
    }
  }
}
::v-deep .el-upload-list__item  {
  width: 280px;
  height: 200px;
  border: none;
}

::v-deep .active {
  .el-input__inner {
    border: 1px solid #6357ff;
    border-color: #6357ff !important;
  }
}
::v-deep .el-form-item__error {
  top: 20%;
  left: 82%;
}

::v-deep .test-upload {
  .el-form-item__error {
    top: 100%;
    left: 0%;
  }
  // .back {
  //   .el-form-item__content {
  //     margin-left: 0!important;
  //   }
  // }
}

::v-deep .hide {
  .el-upload--picture-card {
    display: none;
  }
}

::v-deep .el-upload-list__item-status-label {
  ::before {
    content: '重新上传';
    color: #fff;
    transform: rotate(0);
  }
  border-radius: 0 0 0 4px;
  background-color: #363636;
  right: 0;
  width: 52px;
  transform: rotate(0);
  box-shadow: none;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item-status-label i {
  transform: rotate(0)
}

::v-deep .cardNumber {
  .el-form-item__label {
    &::before {
      content: '*'!important;
      color: #f56c6c!important;
      margin-right: 4px!important;
    }
  }
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner  {
  background-color: #6357ff;
  border-color: #6357ff;
}

::v-deep .el-button {
  background: linear-gradient(to right, #fecee5 , #f7b5f6 , #d1acfa)!important;
  color: #fff;
  border: none;
  font-size: 18px;
  padding: 10px 50px;
}
</style>
