<template>
	<div class="add-goods">
		<!-- <div class="container-global-title">
			<h1>添加商品</h1>
		</div> -->
		<el-form
			:model="form"
			ref="form"
			status-icon
			label-width="100px"
			class="form"
			:rules="rules">
			<!-- <el-form-item label="商品类型" prop="type">
				<el-radio-group v-model="form.type" @input="handleInput()">
					<el-radio
						:label="item.value"
						name="type"
						v-for="item in checkList"
						:key="item.value"
						>{{ item.label }}</el-radio
					>
				</el-radio-group>
			</el-form-item> -->

			<el-form-item label="商品标题" prop="title">
				<el-input
					placeholder="输入商品标题"
					@focus="handleFocu('title')"
					:class="[active === 'title' ? 'active' : '']"
					maxlength="60"
					show-word-limit
					v-model="form.title" />
			</el-form-item>

			<el-form-item label="商品价格" prop="price" v-if="form.type === 1">
				<el-input
					type="number"
					placeholder="输入商品价格"
					@focus="handleFocu('price')"
					:class="[active === 'price' ? 'active' : '', 'is-price']"
					suffix-icon="el-icon-date"
					v-model="form.price" />
			</el-form-item>

			<!-- 实体 -->
			<el-form-item label="商品邮寄" v-if="form.type === 2">
				<div class="isPost">
					<el-radio-group v-model="form.isPost">
						<el-radio :label="0" name="type">包邮</el-radio>
						<el-radio :label="1" name="type">不包邮</el-radio>
					</el-radio-group>
					<!-- v-if="form.isPost == 'noPost'" -->
					<div v-if="form.isPost === 1">
						<el-input
							:class="[active === 'postage' ? 'active' : '']"
							placeholder="请输入运费或到付"
							@focus="handleFocu('postage')"
							v-model="form.postage" />
					</div>
				</div>
			</el-form-item>

			<el-form-item label="上传商品图片" class="picture-card">
				<div class="upload-box">
					<span>*请上传清晰的商品图片，最多可上传15张; </span>
					<span> {{ fileList.length }}/15 </span>
				</div>
				<el-button
					style="margin-bottom: 15px"
					@click="handlePicSort"
					v-if="form.goodPic.length >= 2">
					图片排序</el-button
				>
				<el-upload
					action="/api/base/upload"
					list-type="picture-card"
					multiple
					:limit="15"
					:file-list="fileList"
					:before-upload="beforeAvatarUpload"
					:on-preview="handlePictureCardPreview"
					:on-success="handleAvatarSuccess"
					:on-remove="handleRemove">
					<i class="el-icon-picture-outline"></i>
				</el-upload>
			</el-form-item>

			<!-- 虚拟 -->
			<el-form-item label="添加商品标签" v-if="form.type === 1">
				<add-label @ok="handleLabels" ref="addLabel" />
			</el-form-item>

			<!-- 实体 -->
			<el-form-item label="商品规格" v-if="form.type === 2">
				<span
					>商品规格类型如尺寸、大小、重量、颜色等，如需填写可自定义填写，最多可添加三个</span
				>
				<div class="all-container">
					<div
						class="input-icon"
						v-for="(item, index) in headerList"
						:key="index">
						<el-input
							v-model="item.value"
							placeholder="请输入内容"
							@change="
								(val) => {
									handlePushHeader(val, item, index);
								}
							" />
						<el-popconfirm
							popper-class="my-popconfirm"
							title="确定删除吗？"
							@confirm="handleColumn(item)">
							<i class="el-icon-delete" slot="reference"></i>
						</el-popconfirm>
					</div>
					<i class="el-icon-plus hover" @click="handleAddHeader">新增参数</i>
				</div>
			</el-form-item>
			<el-form-item label="商品属性" v-if="form.type === 2">
				<div class="upload-box">
					<span>请填写商品规格/库存/价格:</span
					><i class="el-icon-plus hover column" @click="handleTableRow"
						>新增参数</i
					>
				</div>
				<el-table
					:data="tableData"
					stripe
					ref="table"
					class="table"
					border
					:header-cell-style="{ background: '#fafafa' }">
					<el-table-column v-for="item in tableHeader" :key="item">
						<template slot="header">
							{{ item }}
						</template>
						<template slot-scope="scope">
							<div class="delete-table">
								<el-input
									v-model="scope.row[item]"
									:placeholder="'请输入' + item + '参数'"
									@input="
										(val) => {
											handleTableData(val, item, scope);
										}
									" />
							</div>
						</template>
					</el-table-column>
					<el-table-column width="180">
						<template slot="header">
							<span>操作</span>
						</template>
						<template slot-scope="scope">
							<el-popconfirm
								popper-class="my-popconfirm"
								class="popconfirm"
								title="确定删除吗？"
								@confirm="handleRow(scope)">
								<i class="el-icon-delete" slot="reference"></i>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
			</el-form-item>

			<!-- 虚拟 -->
			<el-form-item label="百度网盘地址" v-if="form.type === 1" prop="url">
				<el-input
					placeholder="输入百度网盘地址"
					@focus="handleFocu('url')"
					:class="[active === 'url' ? 'active' : '']"
					v-model="form.url" />
			</el-form-item>

			<el-form-item label="提取码" v-if="form.type === 1" prop="pwd">
				<el-input
					placeholder="输入提取码"
					@focus="handleFocu('pwd')"
					:class="[active === 'pwd' ? 'active' : '']"
					v-model="form.pwd" />
			</el-form-item>

			<!-- 实体 -->
			<el-form-item label="物流信息" v-else prop="logistics">
				<div class="logistics">
					<!-- <el-input
						v-model="form.price"
						class="item-logistics"
						placeholder="商品价格"
						:disabled="isDisabled.price" />
					<el-input
						v-model="form.inventory"
						class="item-logistics"
						placeholder="商品库存"
						:disabled="isDisabled.inventory" /> -->
					<el-input
						v-model="form.sendTime"
						class="item-logistics"
						suffix-icon="el-icon-date"
						placeholder="发货时间, 如7天/24小时" />
					<el-input
						v-model="form.logistics"
						class="item-logistics"
						suffix-icon="el-icon-date"
						placeholder="发货物流,如顺丰、邮政等" />
				</div>
				<!-- <el-input
					v-model="form.Contact"
					class="item-logistics"
					placeholder="客服联系方式，如微信、QQ、手机号" /> -->
			</el-form-item>
			<!-- v-if="form.type === 1" -->

			<el-form-item label="客服联系方式" prop="Contact">
				<el-input
					v-model="form.Contact"
					class="item-logistics"
					placeholder="客服联系方式，如微信、QQ、手机号" />
			</el-form-item>
		</el-form>

		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="" />
		</el-dialog>
		<PicDialogSort ref="PicSortDiaLog" @ok="handleSortOver" />
	</div>
</template>

<script>
import AddLabel from "@/components/AddLabel";
import { rules } from "@/utils/validate";
import PicDialogSort from "@/components/dialogs/PicDialogSort.vue";

export default {
	components: { AddLabel, PicDialogSort },
	props: {
		type: {
			type: Number,
			default: 1,
		},
		radio: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		const tableHeyKeyType = {
			0: "one",
			1: "two",
			2: "three",
		};

		return {
			isPlus: false,
			rules,
			tableHeyKeyType,

			checkList: [
				{ label: "虚拟商品", value: 1 },
				{ label: "实体商品", value: 2 },
			],

			dialogVisible: false,
			dialogImageUrl: "",

			tableHeader: ["价格", "库存"],
			tableHeaderKey: [],
			pushHeader: "",
			tableData: [
				{
					价格: 0,
					库存: 0,
				},
			],

			form: {
				type: this.type,
				isPost: 0,
				postage: "",
				title: "",
				goodPic: [],
				labelList: [],
				price: "",
				inventory: "",
				sendTime: "",
				Contact: "",
				logistics: "",
				radio: this.radio,
				url: "",
				pwd: "",
			},

			baseUrl: this.$store.state.baseUrl,
			active: "",

			subMitTable: [],
			headerList: [],
		};
	},

	watch: {
		// 监听添加商品类型
		type(val) {
			this.form.type = val;
			if (this.type == 2) {
				this.handleAddHeader();
			} else {
				this.headerList = [];
				this.tableHeader = ["价格", "库存"];
			}
		},
		radio(val) {
			this.form.radio = val;
		},
	},

	computed: {
		fileList() {
			if (!this.form.goodPic.length) return [];
			let arr = this.form.goodPic.map((item) => {
				return {
					url: !!item.includes(this.baseUrl) ? item : this.baseUrl + item,
				};
			});
			return arr;
		},

		isDisabled() {
			let isTrue = { price: false, inventory: false };
			if (this.tableData.length) {
				if (this.tableData.length === 1) {
					isTrue.price = !!Number(this.tableData[0]["价格"]);
					isTrue.inventory = !!Number(this.tableData[0]["库存"]);
				} else {
					isTrue = { price: true, inventory: true };
				}
			}
			// 筛选出最低价格
			if (this.tableData.length) {
				const minPrice = this.tableData.reduce((prev, curr) => {
					return Number(prev["价格"]) < Number(curr["价格"]) ? prev : curr;
				});

				this.form.price = isTrue.price
					? minPrice["价格"] || 0
					: this.form.price;
				this.form.inventory = isTrue.inventory
					? minPrice["库存"]
					: this.form.inventory;
			}
			return isTrue;
		},
	},

	methods: {
		// 图片排序
		handlePicSort() {
			this.$refs.PicSortDiaLog.show(this.form.goodPic);
		},

		// 图片排序结束
		handleSortOver(submitImages) {
			this.form.goodPic = submitImages.map((item) => {
				return item.replaceAll(this.baseUrl, "");
			});
		},
		// 图片预览
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},

		// 图片上传成功
		handleAvatarSuccess(response, file, fileList) {
			if (fileList.every((item) => item.status === "success")) {
				setTimeout(() => {
					// fileList.forEach((item) => {
					// 	// this.form.goodPic.push(item.response.msg);
					// 	this.form.goodPic.push(!item.name ? item.url : item.response.msg);
					// });
					this.form.goodPic = fileList.map((item) => {
						return !item.name ? item.url : item.response.msg;
					});
					this.isLoading(false);
				}, 3000);
			}
		},
		// 图片移除
		handleRemove(file) {
			this.form.goodPic.splice(this.form.goodPic.indexOf(file.url), 1);
		},

		// 图片上传前做限制
		beforeAvatarUpload(file) {
			this.isLoading(true);
		},

		// 获取标签
		handleLabels(labels) {
			this.form.labelList = labels;
		},

		// 添加的规格
		handleAddHeader() {
			if (this.tableHeader.length >= 5)
				return this.$message.error("最多添加5个规格");
			const isAdd = this.headerList.every((item) => !!item.value);
			if (!isAdd) return this.$message.error("请先添加规格");
			this.headerList.unshift({ value: "" });
			// this.tableHeader.unshift("");
		},
		// 添加的规格的值
		handlePushHeader(val, item, index) {
			if (this.tableHeader.some((item) => item === val))
				return this.$message.error("规格不能重复");

			if (this.headerList.length + 2 === this.tableHeader.length)
				this.tableHeader.splice(index, 1);

			this.tableHeader = Array.from(
				new Set([
					...this.headerList
						.filter((item) => !!item.value)
						.map((item) => item.value),
					...this.tableHeader,
				])
			);
			this.tableData = this.tableData.map((item) => {
				return { ...item, [val]: "" };
			});
			this.tableHeaderKey.push({
				[val]: this.tableHeyKeyType[this.tableHeaderKey.length],
				value: val,
			});
		},

		// 添加规格的内容
		handleTableData(val, type, scope) {
			this.tableData = this.tableData.map((item, idx) => {
				if (idx === scope.$index) item[type] = val;
				return item;
			});
		},

		// 添加商品
		handleTableRow() {
			const tableKey = {};
			for (let i = 0; i < this.tableHeader.length; i++) {
				if (this.tableHeader[i]) {
					const item = this.tableHeader[i];
					tableKey[item] = "";
				}
			}
			this.tableData.unshift(tableKey);
		},

		// 删除列数据
		handleColumn(type) {
			if (!type.value) return this.$message.error("当前没有可删除的规格");
			this.headerList.splice(this.headerList.indexOf(type.value), 1);
			this.tableHeader.splice(this.tableHeader.indexOf(type.value), 1);
			this.tableData = this.tableData.map((item) => {
				delete item[type.value];
				return item;
			});
			this.tableHeaderKey = this.tableHeaderKey.filter(
				(item) => item.value !== type.value
			);
		},

		// 删除行数据
		handleRow(scope) {
			this.tableData.splice(scope.$index, 1);
		},

		// 提交
		handleSubmit() {
			try {
				if (!this.form.radio) return this.$message.error("点击同意协议");
				this.$refs.form.validate(async (valid) => {
					if (valid) {
						const errorInfo = this.checkProduct();
						if (!errorInfo.flag) return this.$message.error(errorInfo.msg);
						let params = {};
						// 虚拟
						if (this.form.type === 1) {
							params = {
								good_type: this.form.type,
								good_title: this.form.title,
								good_price: Number(this.form.price),
								good_pic: this.form.goodPic
									.join("|")
									.replaceAll(this.baseUrl, ""),
								good_baidu_url: this.form.url, // 百度网盘地址
								good_baidu_code: this.form.pwd, // 百度网盘提取码
								label: this.form.labelList.join("|"),
								sendcustomer: this.form.Contact,
								good_inventory: 9999, // 无限大
							};
						} else {
							// 实体
							// 对应规格
							const tableData = this.tableData.map((item) => {
								const resObj = {};
								Object.keys(item).forEach((item2) => {
									const findObj = this.tableHeaderKey.find(
										(k) => k.value === item2
									);
									if (findObj) {
										resObj[findObj[item2]] = item[item2];
									}
								});
								return {
									one: "",
									two: "",
									three: "",
									...resObj,
									inventory: Number(item["库存"]),
									price: Number(item["价格"]).toFixed(2),
								};
							});
							// if (tableData.length <= 1)
							// 	return this.$message.warning("商品添加数据需要是多条!");
							if (!this.tableHeaderKey.length)
								return this.$message.warning("最少要有3个商品属性规格!");
							// return;
							params = {
								good_type: this.form.type,
								good_title: this.form.title,
								good_pic: this.form.goodPic
									.join("|")
									.replaceAll(this.baseUrl, ""),
								good_transport_way: this.form.isPost ? "不包邮" : "包邮",
								good_transport_price: this.form.isPost
									? Number(this.form.postage)
									: 0,
								good_price: Number(this.form.price),
								sendtime: this.form.sendTime,
								sendlogistics: this.form.logistics,
								good_inventory: Number(this.form.inventory),
								specificationType: this.subMitTable.join("|"),
								sendcustomer: this.form.Contact,
								label: "",
								Specifications: tableData,
							};
						}
						let res = {};
						if (this.$route.query.good_uuid) {
							params.good_uuid = this.$route.query.good_uuid;
							res = await this.$store.dispatch("goods/uploadGoods", params);
						} else {
							res = await this.$store.dispatch("goods/addGoods", params);
						}
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.subMitTable = [];
							this.tableData = [
								{
									价格: 0,
									库存: 0,
								},
							];
							this.tableHeader = ["价格", "库存"];
							this.$router.push("/auditing");
						} else {
							this.$message.error(res.msg);
						}
					}
				});
			} catch (e) {
				//TODO handle the exception
				console.log(e, "提交商品出问题了");
			}
		},

		// 检测商品类型
		checkProduct() {
			try {
				const errInfo = {
					flag: true,
					key: "",
					msg: "",
				};
				if (this.form.type === 1) {
					// 虚拟
				} else {
					// 实体
					// 判断表格数据是否实体商品是否填写 发货时间 && 发货物流 && 联系方式
					if (!this.tableHeader.every((item) => !!item)) {
						errInfo.flag = false;
						errInfo.msg = `商品规格至少需要3个`;
						return errInfo;
					}
					const allLogistics = ["sendTime", "Contact", "logistics"];
					const allTips =
						this.isDisabled.inventory && this.isDisabled.price
							? allLogistics
							: [...allLogistics, "inventory", "price"];
					const msgType = {
						sendTime: "发货时间",
						Contact: "联系方式",
						logistics: "发货物流",
						inventory: "库存",
						price: "价格",
					};

					for (let i = 0; i < allTips.length; i++) {
						if (!!!this.form[allTips[i]]) {
							errInfo.flag = false;
							errInfo.key = allTips[i];
							errInfo.msg = `${msgType[allTips[i]]}不能为空`;
						}
					}

					if (!!this.form.isPost && !this.form.postage) {
						errInfo.flag = false;
						errInfo.key = this.form.isPost;
						errInfo.msg = `邮费不能为空`;
					}
					const errMsg = [];
					this.tableData.forEach((item) => {
						Object.keys(item).forEach((item1) => {
							if (!!!item[item1]) errMsg.push(item1);
						});
					});
					// 物流信息中商品价格和商品库存被禁用掉就不需要校验
					if (
						errMsg.length &&
						this.isDisabled.price &&
						this.isDisabled.inventory
					) {
						errInfo.flag = false;
						errInfo.key = errMsg.join("");
						errInfo.msg = `表格中${Array.from(new Set(errMsg)).join(
							"、"
						)}不能为空`;
					}

					const isOptions = ["库存", "价格"];
					// 筛选的表格数据
					this.subMitTable = this.tableHeader
						.map((item) => {
							if (!isOptions.includes(item)) {
								const select = this.tableData.map((item1) => item1[item]);
								return { title: item, subTitle: Array.from(new Set(select)) };
							}
						})
						.filter((item) => !!item)
						.map((item) => {
							return `title:${item.title},subTitle:${item.subTitle.join(
								"-"
							)},titleKey:${
								this.tableHeaderKey.find((item1) => item1.value === item.title)[
									item.title
								]
							}`;
						});
				}

				this.form.goodPic = this.form.goodPic.filter((item) => !!item);
				if (!this.form.goodPic.length) {
					errInfo.flag = false;
					errInfo.key = "goodPic";
					errInfo.msg = "请上传商品图";
				}
				return errInfo;
			} catch (e) {
				console.log(e, "检测商品类型出错了~");
			}
		},

		handleFocu(key) {
			this.active = key;
		},

		// 获取虚拟商品详情
		async getGoodsVDetail() {
			try {
				const uuid = this.$route.query.good_uuid;
				const { data, total } = await this.$store.dispatch(
					"goods/getVitualDetail",
					{ id: uuid }
				);
				data.good_download = JSON.parse(data.good_download);
				data.label =
					data.label.map((item) => {
						return item?.label_name;
					}) || [];

				this.$refs.addLabel.show(data.label);
				this.form = {
					type: data.good_type,
					url: data.good_download.good_baidu_url,
					pwd: data.good_download.good_baidu_code,
					goodPic: data.good_pic,
					price: data.good_price,
					title: data.good_title,
					labelList: data.label,
					Contact: JSON.parse(data.good_sendinfo).sendcustomer || "",
				};
			} catch (e) {
				console.log(e, "获取虚拟商品详情出错了~");
			}
		},

		// 获取实体商品详情
		async getGoodsPDetail() {
			try {
				let { data, total, headerType, tableData } = await this.$store.dispatch(
					"goods/getPhysicalDetail",
					{ id: this.$route.query.good_uuid }
				);
				this.tableData = tableData;
				this.isPlus = !!tableData.length;
				if (!headerType.length) {
					headerType = [];
				}
				this.tableHeader = [
					...headerType.map((item) => item.value),
					"价格",
					"库存",
				];
				this.tableHeaderKey = headerType.map((item) => {
					return {
						value: item.value,
						[item.value]: item.key,
					};
				});
				this.form = {
					type: data.good_type,
					goodPic: data.good_pic,
					isPost: data.good_transport.way,
					postage: data.good_transport.price,
					title: data.good_title,
					price: data.good_price || 0,
					inventory: data.good_inventory || 0,
					sendTime: data.good_sendinfo.sendtime,
					Contact: data.good_sendinfo.sendcustomer,
					logistics: data.good_sendinfo.sendlogistics,
				};
				this.form.price = data.good_price;
			} catch (e) {
				//TODO handle the exception
				console.log(e, "获取实体商品详情出错了~");
			}
		},
	},

	async mounted() {
		const typeSell = this.$route.query.type;
		const functionType = {
			1: "getGoodsVDetail",
			2: "getGoodsPDetail",
		};
		if (!!typeSell) {
			await this[functionType[typeSell]]();
		}
	},
};
</script>
<style scoped lang="scss">
.add-goods {
	.all-container {
		display: flex;
		// justify-content: space-between;
		align-items: center;
		.input-icon {
			display: flex;
			justify-content: space-around;
			margin-right: 15px;
		}
	}
	.table-box {
		display: flex;
		justify-content: flex-start;
		.table-btn-column {
			display: flex;
			flex-direction: column;
			padding-top: 3%;
			.column {
				color: #000;
				margin-top: 75%;
			}
		}
	}
	.plus {
		cursor: pointer;
		color: aqua;
	}
	.table {
		margin-bottom: 10px;
	}

	a {
		color: #000;
	}
	.form {
		.delete-table {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.el-icon-delete {
			margin-left: 15px;
			cursor: pointer;
		}
	}
	.center {
		padding-top: 20px;
		border-top: 1px solid #666;
		text-align: center;
		.submit {
			margin-top: 10px;
			.sale-class {
				background: linear-gradient(to right, #fecee5, #f8b6f5, #d1acfa);
				border: none;
				color: #fff;
				border-radius: calc(0.5rem * 4);
			}
		}
	}
	.logistics {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		.item-logistics {
			width: 45%;
			margin-top: 10px;
		}
		::v-deep .el-input__icon {
			width: 48px;
			font-size: 12px;
		}
		::v-deep .el-icon-date:before {
			content: "*必填";
			color: red;
		}
	}
	.upload-box {
		display: flex;
		justify-content: space-between;
		color: #d5d5d5;
	}
}

::v-deep .el-table::before {
	background: none;
}
::v-deep .isPost {
	.el-radio-group {
		height: 40px;
		display: flex;
		align-items: center;
	}
}

::v-deep .active {
	.el-input__inner {
		border: 1px solid #6357ff;
	}
}

::v-deep .hover {
	color: #d5d5d5;
	&:hover {
		color: #6357ff;
		cursor: pointer;
	}
}
::v-deep .el-upload-list--picture-card .el-upload-list__item-actions {
	&:hover {
		bottom: 0;
		height: auto;
		top: 110px;
	}
}
::v-deep .el-form-item__label:active {
	color: #6357ff;
}
::v-deep .is-price {
	.el-icon-date:before {
		content: "元";
	}
}
// ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
// .el-checkbox__input.is-indeterminate .el-checkbox__inner {
// 	background-color: #6357ff;
// 	border-color: #6357ff;
// }
// ::v-deep .el-checkbox__inner:hover {
// 	border-color: #6357ff;
// }

// ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
// 	color: #6357ff;
// }

// ::v-deep
// 取消input的上下箭头
::v-deep input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
}

::v-deep input::-webkit-outer-spin-button {
	-webkit-appearance: none !important;
}
</style>
